import React from 'react';

import styled from '@emotion/styled';

import { Swiper, SwiperSlide } from 'swiper/react';
import { IsliderProduct } from '../../types/config';
import { useNavigate, useParams } from 'react-router-dom';
import { Pagination, Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { ReactSVG } from 'react-svg';
import { imgSrcByEnv } from '../../../config/imgSrcByEnv';
import { normalizeNameForUrl } from '../../helpers';

export const ProductImagesSlider = ({
  SecondLineElementWidth,
  title,
  products,
  className,
}: any) => {
  const navigate = useNavigate();
  const { storeId, harvestId } = useParams();
  return (
    <ProductSliderStyled SecondLineElementWidth={SecondLineElementWidth}>
      <div>
        <h1>{title || 'New Deals'}</h1>
        {products?.length ? (
          <SwiperStyled key={Math.random()}>
            <Swiper
              breakpoints={{
                991: {
                  slidesPerView: 4,
                },
                830: {
                  slidesPerView: 3,
                },
                550: {
                  slidesPerView: 2,
                },
                480: {
                  slidesPerView: 1,
                },
              }}
              pagination={{
                clickable: true,
              }}
              navigation={{
                nextEl: `.arrow-right-image` + className,
                prevEl: `.arrow-left-image` + className,
              }}
              modules={[Pagination, Navigation]}
              spaceBetween={16}
              className={className}
            >
              {products?.map((product: IsliderProduct) => {
                const price = product.RegularPrice
                  ? product.RegularPrice
                  : product.Price;
                return (
                  <SwiperSlide key={Math.random()}>
                    <img
                      src={`${imgSrcByEnv()}${
                        product?.Gallery[0]?.image?.fullpath
                      }`}
                      alt={product?.Name}
                      onClick={() =>
                        navigate(
                          `/${storeId}/${harvestId}/ips/${
                            product?.id
                          }__${normalizeNameForUrl(product?.Name)}_${price}`
                        )
                      }
                    />
                  </SwiperSlide>
                );
              })}
            </Swiper>
            <ReactSVG
              src="/files/generic/img/right-chevron.svg"
              className={`arrow-left-image` + className}
            />
            <ReactSVG
              src="/files/generic/img/right-chevron.svg"
              className={'arrow-right-image' + className}
            />
          </SwiperStyled>
        ) : (
          <Swiper slidesPerView={2.5} spaceBetween={16} className="mySwiper">
            <SwiperSlide>
              <img
                src="/files/generic/img/tallRectangle.svg"
                alt="generic/img"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img src="/files/generic/img/wideSquare.svg" alt="generic/img" />
            </SwiperSlide>
            <SwiperSlide>
              <img src="/files/generic/img/3-boxes.svg" alt="generic/img" />
            </SwiperSlide>
          </Swiper>
        )}
      </div>
    </ProductSliderStyled>
  );
};
const ProductSliderStyled = styled.div<{ SecondLineElementWidth: string }>`
  background: #ffffff;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;

  padding: 19px 5px 16px 3px;

  img {
    height: 130px;
    width: 100%;
    object-fit: contain;
    cursor: pointer;

    @media (max-width: 768px) {
      height: 260px;
    }
  }

  & > div > h1 {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #000000;
    margin-bottom: 24px;
    padding-left: 30px;
  }
`;

const SwiperStyled = styled.div`
  position: relative;
  background-color: #ffffff;
  padding: 0 30px;

  .swiper {
    padding-bottom: 30px;
  }

  & > div:first-of-type {
    .swiper-pagination {
      bottom: 0;
    }
  }
  .swiper-button-disabled {
    visibility: hidden;
  }
  & > div:nth-of-type(2) {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%) rotate(180deg);
    z-index: 1000;
  }
  & > div:last-of-type {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1000;
  }
`;
