import React from 'react';

import { useConfiguration } from '../../hooks/useConfiguration';
import { Carousel } from '../../components/Carousel';
import { PopularSections } from '../../components/PopularSections';
import { ProductImagesSlider } from '../../components/ProductImagesSlider';
import { Code } from 'react-content-loader';
import { useParams } from 'react-router-dom';
import { PopularProducts } from '../../components/PopularProducts';
import { useMainRoute } from './useMainRoute';
import { MainRouteStyled } from './styles';
import { useQuery } from '@apollo/client';
import { GET_LOCALIZED } from '../../queries'

export const MainRoute = () => {
  const { shuffledProducts, categories, similarLoading } = useMainRoute();

  const { storeId = '', harvestId = '' } = useParams();

  const {
    loading,
    CarouselWidth,
    CarouselItems,
    SecondLineElementWidth,
    SecondLineProductsExpositionName,
    SecondLineSliderName,
    SecondLineSliderProducts,
    SecondLineExpositionProducts,
    LanguageVersion
  } = useConfiguration( { storeId, harvestId });

  const {data: translation} = useQuery(
    GET_LOCALIZED,
    {
      variables: {
        defaultLanguage: LanguageVersion,
      },
    }
  );

  if (loading) {
    return (
    <div style={{ marginTop: 24 }}>
      <Code />
    </div> );
   } else {
    return (
      <MainRouteStyled
        CarouselWidth={CarouselWidth}
        SecondLineElementWidth={SecondLineElementWidth}
      >
        <div className="carousel-container">
          <Carousel CarouselItems={CarouselItems}></Carousel>
        </div>

        {CarouselWidth !== 'Full' && (
          <div className="popular-sections-container">
            <PopularSections
              CarouselWidth={CarouselWidth}
              categories={categories}
            ></PopularSections>
          </div>
        )}

        <div className="product-image-slider-container">
          <ProductImagesSlider
            title={SecondLineSliderName}
            products={SecondLineSliderProducts}
            className="swiper1"
          ></ProductImagesSlider>
        </div>

        <div className="product-exposition-container">
          <ProductImagesSlider
            title={SecondLineProductsExpositionName}
            products={SecondLineExpositionProducts}
            className="swiper2"
          ></ProductImagesSlider>
        </div>

        {CarouselWidth === 'Full' && (
          <div className="popular-sections-container">
            <PopularSections
              CarouselWidth={CarouselWidth}
              categories={categories}
            ></PopularSections>
          </div>
        )}

        <div className="simmilar">
          <PopularProducts
            products={shuffledProducts.shuffledRecommended}
            className="swiper3"
            //title="Popular products"
            title={translation?.getLocalization.PopularProducts}
          ></PopularProducts>
        </div>

        <div className="simmilar">
          <PopularProducts
            products={shuffledProducts.shuffledSimilar}
            className="swiper4"
            //title="Popular in"
            title={translation?.getLocalization.PopularIn}
          ></PopularProducts>
        </div>
      </MainRouteStyled>
    );
   }
};
