import styled from '@emotion/styled';

export const PdpRouteStyled = styled.div`
  .details {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: flex-start;

    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
    }
  }

  & > h2:nth-of-type(1) {
    margin-top: 96px;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #000000;

    @media (max-width: 768px) {
      margin-top: 30px;
    }
  }

  div > h2 {
    margin-top: 96px;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #000000;
    margin-bottom: 24px;

    @media (max-width: 768px) {
      margin-top: 30px;
    }
  }

  h2:nth-of-type(2) {
    margin: 96px 0 24px 0px;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #000000;
  }

  h3:nth-of-type(1) {
    margin: 48px 0 35px;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #000000;

    @media (max-width: 768px) {
      margin: 24px 0 17px;
    }
  }

  h3:nth-of-type(2) {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
    margin-bottom: 24px;
  }

  h3:nth-of-type(3) {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
    margin-bottom: 16px;
  }

  & > div > p {
    @media (max-width: 768px) {
    max-width: 647px;
    }

    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    margin-bottom: 21px;
  }

  hr:nth-of-type(1) {
    margin: 16px 0;
  }

  hr:nth-of-type(2) {
    margin: 48px 0 64px;
  }

  @media (max-width: 768px) {
    hr {
      margin: 24px 0 !important;
    }
  }

  .reviews {
    max-width: 743px;
  }

  .specifications {
    margin-bottom: 32px;

    & > h3:nth-of-type(1) {
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      color: #000000;
    }

    & > h3:nth-of-type(2) {
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      color: #000000;
    }

    & > div {
      @media (max-width: 768px) {
      max-width: 744px;
      }

      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #000000;
      margin-left: 16px;
    }
  }
  .additional-tab {
    & > h3 {
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      color: #000000;
    }

    & > p {
      @media (max-width: 768px) {
      max-width: 744px;
      }

      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #000000;
    }
  }
`;
