import styled from '@emotion/styled';
import Button from '@mui/material/Button';

export const CustomLabelStyledDiv = styled.div<{
  LabelColor?: string;
  LabelTextColor?: string;
}>`
  font-weight: bold;
  margin: 10px;
  padding: 5px;
  border: 1px solid ${({LabelColor}) => LabelColor != '' ? LabelColor : '' };
  border-radius: 3px;
  width: max-content;
  background-color: ${({LabelColor}) => LabelColor != '' ? LabelColor : '' };
  color: ${({LabelTextColor}) => LabelTextColor != '' ? LabelTextColor : '' };

  margin-left:0;
`

export const PdpCardStyled = styled.div`
  margin-top: 80px;
  background: #ffffff;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 24px 24px 35px;
  grid-column: 3 / span 1;
  width: 100%;

  @media (max-width: 768px) {
    margin-top: 30px;
  }

  & > p:nth-of-type(1) {
    max-width: 312px;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 16px;
    margin-top: 18px;
  }

  .price {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
    margin: 24px 0;
    & > span {
      color: #777;
      font-weight: 400;
    }
  }

  .addToCartPDPButton {
    margin: 15px 0 15px 0;
    float: right;
  }

  .oldPrice {
    font-size: 15px;
    color: #999999;
    text-decoration: line-through
  }

  .attributes {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #000000;
    margin: 10px 0;
  }

  .shipping-methods {
    .container {
      display: flex;
      gap: 10px;
    }
    p {
      font-weight: 700;
      font-size: 12px;
      line-height: 15px;
      color: #000000;
      margin-bottom: 24px;
      text-decoration-line: none;
      margin-bottom: 16px;
    }
    .description {
      margin: 20px 0px;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
    }
  }

  .brand-line {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p:nth-of-type(1) {
      font-weight: 400;
      font-size: 12px;
      line-height: 12px;
      text-decoration-line: underline;
      color: #000000;
      display: inline-block;
    }

    & > div {
      display: flex;
      align-items: center;
    }
  }

  & > p:nth-of-type(2) {
    margin-bottom: 9.33px;
  }

  & > p:nth-of-type(4) {
    margin: 12px 0;
  }

  & > hr {
    margin: 24px 0 !important;
  }

  & > p > span {
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
    margin-bottom: 24px;
  }

  .rating {
    display: flex;
    align-items: center;
    & > span {
      margin-left: 5px;
    }
  }
  .cart {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 24px;

    .cart-manipulation {
      width: 30%;
      & > span {
        margin: 0 10px;
        @media (max-width: 991px) {
          margin: 0 3px;
        }
      }
    }

    & > button {
      width: 70%;
    }
  }

  .variants-container {
    & > p {
      margin: 10px 0;
    }
    & > .variants-inner-container {
      & > p {
        font-weight: 500;
        font-size: 12px;
        margin: 10px 0;
      }
  }
  .variants {
    display: flex;
    gap: 16px;
    max-width: 312px;
    overflow-x: auto;

    @media (max-width: 768px) {
      max-width: 100%;
    }

    .active {
      border: 2px solid ${({ theme }: any) => theme.productAddToCartBg};
    }

    & > div {
      border: 1px solid grey;
      border-radius: 3px;
      max-width: 105px;
      min-width: 100px;
      min-height: 70px;
      padding: 8px;
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        height: 85px;
        width: 85px;
        object-fit: contain;
        display: block;
      }
      p {
        margin-top: 8px;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #000000;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 5;
      }
      .variant-price {
        font-weight: 700;
      }
    }
  }
`;

export const AddButton = styled.button`
  background: transparent;
  border: none;
  text-decoration: underline;
`;

export const ButtonStyled = styled(Button)`
  color: #ffffff;
  background: ${(props: any) => props.theme.productAddToCartBg};
  padding: 9px 16px;
  border-radius: 3px;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  &:hover {
    background: ${(props: any) => props.theme.productAddToCartBg};
  }
`;

export const ShippingMethodStyled = styled.button<{
  isActive?: boolean;
}>`
  &:hover {
    cursor: pointer;
  }
  height: 40px;
  text-align: left;
  text-decoration: none;
  padding: 10px;
  flex: 1;
  background-color: ${({ isActive }) => (isActive ? '#f4fdfe' : '#ffffff')};
  box-shadow: ${({ isActive, theme }: any) =>
    isActive
      ? `0 0 0 0.125rem ${theme.productAddToCartBg}`
      : '0 0 0 0.0625rem #bbc8d1'};
  border-radius: 5px;
  border: none;

  white-space: nowrap;

  svg {
    color: ${({ theme }: any) => theme.productAddToCartBg};
  }
`;
