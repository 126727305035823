import styled from '@emotion/styled';

export const SearchInputStyled = styled.div`
  position: relative;

  img {
    width: 22px;
    position: absolute;
    right: 10px;
    top: 10px;
    &:hover {
      cursor: pointer;
    }
  }
`;

export const Input = styled.input`
  width: 100%;
  padding: 0px 10px;
  border-radius: 25px;
  background: #f7f7f7;
  color: #afafaf;
  border: none;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 12px;
  height: 40px;
`;
