import React from 'react';

import styled from '@emotion/styled';
import Button from '@mui/material/Button';
import { useQuery } from '@apollo/client';
import { GET_LOCALIZED } from '../../queries'
import { useNavigate, useParams } from 'react-router-dom';
import { useConfiguration } from '../../hooks/useConfiguration';

export const FilterModalButtons = ({ onSubmit, onClear }: any) => {

  const { storeId = '', harvestId = '' } = useParams();

  const { LanguageVersion } = useConfiguration({ storeId, harvestId });

  const {data: translation} = useQuery(
    GET_LOCALIZED,
    {
      variables: {
        defaultLanguage: LanguageVersion,
      },
    }
  );

  return (
    <ComponentStyled>
      <u onClick={onClear} className="clear-filter">
      {translation?.getLocalization.Clear}
      </u>
      <Button variant="contained" onClick={onSubmit} className="submit-filter">
      {translation?.getLocalization.ViewResults}
      </Button>
    </ComponentStyled>
  );
};

const ComponentStyled = styled.div`
  text-align: center;
  margin-top: 10px;
  position: sticky;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  padding-bottom: 5%;

  u {
    font-size: 1.2rem;
    padding: 13px 0;
    display: block;
  }
  button {
    display: block;
    text-align: center;
    width: 100%;
  }
  .clear-filter {
    cursor: pointer;
  }
`;
