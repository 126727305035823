import React from 'react';

import { Link, useLocation, useParams } from 'react-router-dom';
import { useConfiguration } from '../../hooks/useConfiguration';
import { SearchInput } from '../SearchInput';
import {
  Nav,
  NavItems,
  SgnItem,
  NavContainer,
  Cart,
  LinkStyled,
} from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { ReactSVG } from 'react-svg';
import { useNavigation } from './useNavigation';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { imgSrcByEnv } from '../../../config/imgSrcByEnv';
import { useQuery } from '@apollo/client';
import { GET_LOCALIZED } from '../../queries'

export const Navigation = () => {
  const {
    filteredSuggestions,
    handleLogoClick,
    handleSearchSubmit,
    handleSearchSuggestions,
    handleSuggestionClick,
    handleCartClick,
    totalCartValue,
    suggestionsVisible,
    searchKeyword,
    handleGoBackClick,
  } = useNavigation();

  const { storeId = '', harvestId = '' } = useParams();

  const location = useLocation();

  const { Logo, Currency, LanguageVersion } = useConfiguration({ storeId, harvestId });

  const {data: translation} = useQuery(
    GET_LOCALIZED,
    {
      variables: {
        defaultLanguage: LanguageVersion,
      },
    }
  );

  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

  React.useEffect(() => {
    setIsDrawerOpen(false);
  }, [location]);
  const currency = Currency ? Currency : '$';

  return (
    <NavContainer>
      <Nav>
        <img
          src={`${imgSrcByEnv()}${Logo?.fullpath}`}
          alt="logo"
          onClick={handleLogoClick}
        />

        <NavItems>
          <FontAwesomeIcon
            icon={faArrowLeft}
            className="menu-icon"
            onClick={()=>handleGoBackClick()}
          />

          <Link to="">{translation?.getLocalization.Departments}</Link>
          <Link to="">{translation?.getLocalization.Services}</Link>

          <div className="search-input">
            <SearchInput
              handleSearchSubmit={handleSearchSubmit}
              handleSearchSuggestions={handleSearchSuggestions}
              searchKeyword={searchKeyword}
            ></SearchInput>
            {suggestionsVisible && filteredSuggestions?.length ? (
              <div className="suggestions">
                {filteredSuggestions?.length > 0 &&
                  filteredSuggestions.map(({ key, id }: any, index: number) => (
                    <div
                      className="suggestion"
                      key={index}
                      onClick={() => handleSuggestionClick(id, key)}
                    >
                      <span>{key}</span>
                      <FontAwesomeIcon icon={faArrowUp} />
                    </div>
                  ))}
              </div>
            ) : (
              ''
            )}
          </div>
          <SgnItem>
            <ReactSVG src="/files/generic/img/user.svg" />
            <Link to="">{translation?.getLocalization.SignIn}</Link>
          </SgnItem>

          <Cart onClick={handleCartClick}>
            <ReactSVG src="/files/generic/img/cart.svg" />
            <Link to="">
              {currency}
              {totalCartValue}
            </Link>
          </Cart>
        </NavItems>
      </Nav>
      <Drawer
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(!isDrawerOpen)}
      >
        <List>
          {[
            { name: 'Home', route: '' },
            { name: 'Departments', route: 'departments' },
            { name: 'Services', route: 'services' },
          ].map((text, index) => (
            <LinkStyled
              key={index}
              to={`/${storeId}/${harvestId}/${text.route}`}
            >
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemText primary={text.name} />
                </ListItemButton>
              </ListItem>
            </LinkStyled>
          ))}
        </List>
      </Drawer>
    </NavContainer>
  );
};
