import React from 'react';

import { IAttributes, IProduct } from '../../types';

import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';

import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppContext } from '../../context';
import { actions } from '../../context/actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBox,
  faCar,
  faMinus,
  faPlus,
  faTruck,
} from '@fortawesome/free-solid-svg-icons';

import {
  AddButton,
  ButtonStyled,
  CustomLabelStyledDiv,
  PdpCardStyled,
  ShippingMethodStyled,
} from './styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled as MuiStyled } from '@mui/material/styles';
import { imgSrcByEnv } from '../../../config/imgSrcByEnv';
import { useConfiguration } from '../../hooks/useConfiguration';
import { normalizeNameForUrl } from '../../helpers';
import { VariantsFilter } from '../Filters/VariantsFilter';
import { METHODS } from 'http';
import { contentSquarePageEvent, dynataAddToCart } from '../../helpers';
import { useQuery } from '@apollo/client';
import { GET_LOCALIZED } from '../../queries';

export const PdpCard = ({ product }: { product: IProduct }) => {
  const [newAmount, setNewAmount] = React.useState(0);
  const [prevKey, setPrevKey] = React.useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const { storeId = '', categoryId, harvestId = '', productId } = useParams();
  const {
    dispatch,
    value: { cart },
  } = useAppContext();
  const { Currency, ShipmentMethod } = useConfiguration({
    storeId,
    harvestId,
  });

  const { LanguageVersion } = useConfiguration({ storeId, harvestId });

  const { data: translation } = useQuery(GET_LOCALIZED, {
    variables: {
      defaultLanguage: LanguageVersion,
    },
  });

  let href = window.location.pathname;

  const handleCartClick = () => navigate(`/${storeId}/${harvestId}/cart`);

  const price = product.Price != 0 ? product.Price : product.RegularPrice;
  const currency = Currency ? Currency : '$';

  const [shippingMethodSelected, setShippingMethodSelected] = React.useState(
    {}
  );

  const [shippingMethodDescription, setShippingMethodDescription] =
    React.useState({});

  const pricePerUnit =
    product.PricePerUnit && product.PricePerUnit != '0'
      ? ' (' + product.PricePerUnit + ')'
      : '';

  const shipmentMethods = React.useMemo(() => {
    return (
      ShipmentMethod?.map((item) => {
        const getIcon = (type: string) => {
          type = type.toLowerCase();

          if (type === 'shipping') {
            return <FontAwesomeIcon icon={faTruck} />;
          }
          if (type === 'pickup') {
            return <FontAwesomeIcon icon={faCar} />;
          }
          if (type === 'delivery') {
            return <FontAwesomeIcon icon={faBox} />;
          }
        };

        const getDesc = (type: string) => {
          type = type.toLowerCase();

          if (type === 'shipping') {
            return 'Get it in 2-3 days, Free for Plus, From $5.50 for Club Members';
          }
          if (type === 'pickup') {
            return 'Get it in 2 hours, Free for Plus, $4 for Club Members';
          }
          if (type === 'delivery') {
            return 'Get it in 3 hours, $8 for Plus, $12 for Club Members';
          }
        };

        return {
          icon: getIcon(item.Name),
          name: item.Name,
          description: getDesc(item.Name),
        };
      }) || []
    );
  }, [ShipmentMethod]);

  React.useEffect(() => {
    setShippingMethodSelected(shipmentMethods?.[0]?.name.toLowerCase());
  }, [shipmentMethods]);

  React.useEffect(() => {
    setShippingMethodDescription(shipmentMethods?.[0]?.name.toLowerCase());
  }, [shipmentMethods]);

  const productInCart = cart.find(
    (productInCart: IProduct) => product.id === productInCart?.id
  );

  React.useEffect(() => {
    setNewAmount(productInCart?.amount || 1);
  }, [cart, product]);

  React.useEffect(() => {
    // if pathname is the same, but with '_cartadd...' postfix, the prevKey is the same
    if (location.state?.prevPath.split("_cartadd")[0] === location.pathname.split("_cartadd")[0]) {
      setPrevKey(location.state.prevKey)
    }
  }, [location]);

  React.useEffect(() => {
    setPrevKey(location.key);
  }, []);

  const handleAddToCart = () => {
    const navState = { prevPath: location.pathname, prevKey: prevKey }
    if (newAmount > 0 && href.indexOf('_cartadd') == -1) {
      dispatch(
        {
          type: actions.ADD_TO_CART,
          payload: {
            ...product,
            newAmount,
            shippingMethod: shippingMethodSelected,
          },
        },
        navigate(href + '_cartadd' + newAmount, {replace:true, state: navState})
      );
    } else {
      dispatch(
        {
          type: actions.ADD_TO_CART,
          payload: {
            ...product,
            newAmount,
            shippingMethod: shippingMethodSelected,
          },
        },
        contentSquarePageEvent('addToCart'),
        navigate(href.replace(/_cartadd\d?/, '') + '_cartadd' + newAmount, {replace:true, state: navState})
      );
    }

    if (newAmount === 0) {
      dispatch({
        type: actions.REMOVE_FROM_CART,
        payload: { type: 'REMOVE_ALL', product },
      });
    }
  };

  return (
    <PdpCardStyled>
      <div className="brand-line">
        <p>{product.Brand}</p>
        {/* <div>
          <img src="/files/generic/img/heart.svg" alt="" />
          <AddButton>{translation?.getLocalization.AddToList}</AddButton>
        </div> */}
      </div>
      <p>{product.Name}</p>
      {/* <p>6-pack / 150ml / 400g</p> */}

      {product.RRAmount != 0 ? (
        <div className="rating">
          {product.RROverall && (
            <Stack spacing={1}>
              <Rating
                name="half-rating-read"
                defaultValue={product.RROverall}
                precision={0.5}
                readOnly
              />
            </Stack>
          )}
          {product.RRAmount && <span>({product.RRAmount})</span>}
        </div>
      ) : (
        <div className="rating">
          {
            <Stack spacing={1}>
              <Rating
                name="half-rating-read"
                defaultValue={0}
                precision={0.5}
                readOnly
              />
            </Stack>
          }
          <span>(0)</span>
        </div>
      )}

      {price && (
        <p className="price">
          {product.Price != 0 ? (
            <div>
              {currency}
              {product.Price.toFixed(2)}{' '}
              <span className="oldPrice">
                {currency}
                {product.RegularPrice.toFixed(2)}
              </span>
            </div>
          ) : (
            <div>
              {currency}
              {product.RegularPrice.toFixed(2)}
            </div>
          )}
          {/*currency*/}
          {/*price.toFixed(2)*/}
          <span className="pricePerUnit">{pricePerUnit}</span>
        </p>
      )}

      <div className="attributes">
        {product?.Attribute[0]?.AttrValue &&
          product?.Attribute[0]?.AttrValue != 'value' &&
          product?.Attribute.map((type, index) => (
            <CustomizedoTooltip title={product?.Name}>
              <p>
                {product?.Attribute[index]?.AttrName}:{' '}
                {product?.Attribute[index]?.AttrValue}
              </p>
            </CustomizedoTooltip>
          ))}
      </div>

      {product.LabelText && 
      <CustomLabelStyledDiv 
      LabelColor={product.LabelColorCode}
      LabelTextColor={product.LabelTextColor}>
        {product.LabelText}
      </CustomLabelStyledDiv>}

      <hr></hr>

      {shipmentMethods.length ? (
        <div className="shipping-methods">
          <p>{translation?.getLocalization.ShippingMethod}:</p>
          <div className="container">
            {shipmentMethods.map((method, key) => (
              <ShippingMethod
                key={key}
                isActive={shippingMethodSelected === method.name.toLowerCase()}
                method={method}
                onClick={() =>
                  setShippingMethodSelected(method.name.toLowerCase())
                }
              />
            ))}
          </div>
          <div className="description">
            {shipmentMethods.map((method, key) => (
              <div>
                {shippingMethodSelected === method.name.toLowerCase()
                  ? method.description
                  : ''}
              </div>
            ))}
          </div>
        </div>
      ) : (
        ''
      )}

      <div className="cart">
        <div className="cart-manipulation">
          <IconButton
            size="small"
            onClick={() => newAmount > 1 && setNewAmount(newAmount - 1)}
          >
            <FontAwesomeIcon className="expand" icon={faMinus} />
          </IconButton>
          <span>{newAmount}</span>
          <IconButton
            size="small"
            onClick={() => newAmount <= 11 && setNewAmount(newAmount + 1)}
          >
            <FontAwesomeIcon className="expand" icon={faPlus} />
          </IconButton>
        </div>
        {productInCart?.amount > 0 ? (
          <ButtonStyled variant="contained" onClick={handleAddToCart}>
            {translation?.getLocalization.UpdateCart}
          </ButtonStyled>
        ) : (
          <ButtonStyled variant="contained" onClick={handleAddToCart}>
            {translation?.getLocalization.AddToCart}
          </ButtonStyled>
        )}
      </div>
      <hr />

      {product?.Attribute[0].AttrName &&
        product?.Attribute[0].AttrValue != 'value' && (
          <div className="variants-container">
            {product?.Attribute.map((attribute, index) => {
              return (
                <div className="variants-inner-container">
                  <p>
                    <span>
                      Actual variant: {attribute.AttrName} -{' '}
                      {attribute.AttrValue}
                    </span>
                  </p>
                  <div className="variants" key={index}>
                    {product?.Variants?.map(
                      (variant: IProduct, variant_index: number) => {
                        if (
                          variant?.Attribute[index]?.AttrName &&
                          variant?.Attribute[index]?.AttrValue != 'value' &&
                          product?.Attribute[index].AttrValue !=
                          variant?.Attribute[index]?.AttrValue
                        ) {
                          const brand = variant?.Brand ? variant?.Brand : '';
                          return (
                            <div
                              className={
                                product?.id === variant?.id ? 'active' : ''
                              }
                              key={variant_index}
                              onClick={() =>
                                navigate(
                                  categoryId
                                    ? `/${storeId}/${harvestId}/ip/${categoryId}/${variant?.id
                                    }__${brand}__${normalizeNameForUrl(
                                      variant?.Name
                                    )}_${variant?.RegularPrice.toFixed(2)}`
                                    : `/${storeId}/${harvestId}/ips/${variant?.id
                                    }__${brand}__${normalizeNameForUrl(
                                      variant?.Name
                                    )}_${variant?.RegularPrice.toFixed(2)}`
                                )
                              }
                            >
                              <CustomizedoTooltip title={variant?.Name}>
                                {variant?.Attribute[index]?.AttrValue !=
                                  'value' ? (
                                  <p>
                                    {variant?.Attribute[index]?.AttrName}:{' '}
                                    {variant?.Attribute[index]?.AttrValue}
                                  </p>
                                ) : (
                                  <p>test</p>
                                )}
                              </CustomizedoTooltip>
                              {variant?.RegularPrice && (
                                <p className="variant-price">
                                  {currency}
                                  {variant?.RegularPrice.toFixed(2)}
                                </p>
                              )}
                            </div>
                          );
                        } else if (
                          variant?.Attribute[index]?.AttrName &&
                          variant?.Attribute[index]?.AttrValue != 'value' &&
                          product?.id === variant?.id
                        ) {
                          const brand = variant?.Brand ? variant?.Brand : '';
                          return (
                            <div
                              className={
                                product?.id === variant?.id ? 'active' : ''
                              }
                              key={variant_index}
                              onClick={() =>
                                navigate(
                                  categoryId
                                    ? `/${storeId}/${harvestId}/ip/${categoryId}/${variant?.id
                                    }__${brand}__${normalizeNameForUrl(
                                      variant?.Name
                                    )}_${variant?.RegularPrice.toFixed(2)}`
                                    : `/${storeId}/${harvestId}/ips/${variant?.id
                                    }__${brand}__$${normalizeNameForUrl(
                                      variant?.Name
                                    )}_${variant?.RegularPrice.toFixed(2)}`
                                )
                              }
                            >
                              <CustomizedoTooltip title={variant?.Name}>
                                {variant?.Attribute[index]?.AttrValue !=
                                  'value' ? (
                                  <p>
                                    {variant?.Attribute[index]?.AttrName}:{' '}
                                    {variant?.Attribute[index]?.AttrValue}
                                  </p>
                                ) : (
                                  <p></p>
                                )}
                              </CustomizedoTooltip>
                              {variant?.RegularPrice &&
                                variant?.Attribute[index]?.AttrValue !=
                                'value' && (
                                  <p className="variant-price">
                                    {currency}
                                    {variant?.RegularPrice.toFixed(2)}
                                  </p>
                                )}
                            </div>
                          );
                        } else {
                          return '';
                        }
                      }
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        )}

      <div className="addToCartPDPButton">
        <ButtonStyled variant="contained" onClick={handleCartClick}>
          {translation?.getLocalization.ReviewCart}
        </ButtonStyled>
      </div>
    </PdpCardStyled>
  );
};

const ShippingMethod = ({
  method,
  isActive,
  onClick,
}: {
  method: { name: string; icon: any; description: any };
  isActive?: boolean;
  onClick: () => void;
}) => {
  return (
    <ShippingMethodStyled onClick={onClick} isActive={isActive}>
      {method.icon} {method.name}
    </ShippingMethodStyled>
  );
};

const CustomizedoTooltip = MuiStyled(
  ({ className, title, icon, children }: any) => (
    <Tooltip title={title} classes={{ popper: className }}>
      {children}
    </Tooltip>
  )
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 12,
  },
}));
