import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

export const NavContainer = styled.div`
  @media (max-width: 1128px) {
    padding: 0 15px;
  }

  @media (max-width: 768px) {
    position: sticky;
    top: 0;
    z-index: 9999;
  }

  color: ${(props: any) =>
    props.theme.navBg === '#FFFFFF' ? '#000000' : '#FFFFFF'};

  a {
    color: ${(props: any) =>
      props.theme.navBg === '#FFFFFF' ? '#000000' : '#FFFFFF'};
    text-decoration: none !important;
  }

  background: ${(props: any) => {
    if (props.theme.navBg === '#232F3E') {
      return 'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #232F3E';
    } else {
      return props.theme.navBg;
    }
  }};
`;

export const LinkStyled = styled(Link)`
  text-decoration: none;
  color: #000;
  .MuiTypography-root {
    font-size: 16px;
    padding: 0 10px;
  }
`;

export const Nav = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 88px;
  align-items: center;
  max-width: 1128px;
  margin: 0 auto;

  & > img {
    max-width: 130px;
    max-height: 60px;
    cursor: pointer;
    display: block;
    @media (max-width: 768px) {
      display: none;
    }
  }
  .menu-icon {
    display: block;
    @media (min-width: 768px) {
      display: none;
    }

    font-size: 24px;
  }
`;

export const NavItems = styled.div`
  flex: 1;
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 12px;
  display: flex;
  gap: 20px;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 768px) {
    gap: 0;
    justify-content: space-between;
  }

  & > a {
    display: block;

    color: ${(props: any) =>
      props?.theme?.navFontColor ? props?.theme?.navFontColor : '#FFFFFF'};

    @media (max-width: 768px) {
      display: none;
    }
  }

  .search-input {
    position: relative;
    @media (min-width: 1100px) {
      min-width: 552px;
    }
    @media (max-width: 768px) {
      width: 80%;
      margin: 0 10px;
    }
  }

  .suggestions {
    position: absolute;
    z-index: 1000;
    top: 45px;
    width: 100%;
    background: ${({ theme }: any) => theme?.navBg};
    border: ${({ theme }: any) => `1px solid ${theme?.navBg}`};
    border-top: none;
    padding: 15px;
    .suggestion {
      display: flex;
      justify-content: space-between;
      padding: 10px 0;
      cursor: pointer;
    }
  }
`;

export const SgnItem = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    display: none;
  }

  svg {
    margin-right: 10px;
    fill: #fff;
    stroke: ${(props: any) =>
      props?.theme?.navFontColor ? props?.theme?.navFontColor : '#FFFFFF'};
  }
  > a {
    color: ${(props: any) =>
            props?.theme?.navFontColor ? props?.theme?.navFontColor : '#FFFFFF'}; 
  }
`;

export const Cart = styled.div`
  display: flex;
  align-items: center;
  a {
    text-align: end;
    min-width: 48px;
    @media (max-width: 768px) {
      min-width: 38px;
    }
    color: ${(props: any) =>
            props?.theme?.navFontColor ? props?.theme?.navFontColor : '#FFFFFF'};
  }
  svg {
    margin-right: 10px;
    fill: #fff;
    stroke: ${(props: any) =>
      props?.theme?.navFontColor ? props?.theme?.navFontColor : '#FFFFFF'};
  }
`;
