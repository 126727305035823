import React from 'react';

import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { FilterModalCommonStyles } from '../commonStyles';
import styled from '@emotion/styled';
import { FilterModalButtons } from '../../FilterModalButtons';
import { actions } from '../../../context/actions';
import { useAppContext } from '../../../context';
import { useParams } from 'react-router-dom';
import { useConfiguration } from '../../../hooks/useConfiguration';
import { GET_LOCALIZED } from '../../../queries';
import { useQuery } from '@apollo/client';

export const VariantsFilter = ({ buttonsOff }: any) => {
  const {
    value: { searchFilters },
    dispatch,
  } = useAppContext();

  const variantsFilter = searchFilters.find(
    (filter: any) => filter.name === 'Variants'
  );

  const { storeId = '', termId, harvestId = '' } = useParams();

  const { LanguageVersion } = useConfiguration({ storeId, harvestId });

  const {data: translation} = useQuery(
    GET_LOCALIZED,
    {
      variables: {
        defaultLanguage: LanguageVersion,
      },
    }
  );

  const handleSubmit = () => {
    dispatch({
      type: actions.ADD_SEARCH_FILTER,
      payload: { name: 'Variants', value },
    });
    dispatch({
      type: actions.TOGGLE_MODAL,
      payload: { isModal: false, modalContent: null },
    });
  };

  const handleClear = () => {
    setValue(null);
    dispatch({
      type: actions.REMOVE_SEARCH_FILTER,
      payload: { name: 'Variants', value: null },
    });
  };

  const [value, setValue] = React.useState(variantsFilter?.value || '');

  return (
    <FilterModalCommonStyles buttonsOff={buttonsOff}>
      <ComponentStyled>
        <h2>{translation?.getLocalization.Variants}</h2>

        <FormControl>
          <RadioGroup onChange={(e) => setValue(e.target.value)} value={value}>
            <FormControlLabel
              value="yes"
              control={<Radio />}
              label={translation?.getLocalization.ShowProductWithVariants}
            />
            <FormControlLabel
              value="no"
              control={<Radio />}
              label={translation?.getLocalization.ShowProductWithNoVariants ?
                translation?.getLocalization.ShowProductWithNoVariants :
                'Show products with no variants'}
            />
          </RadioGroup>
        </FormControl>
        <div className="buttons">
          <FilterModalButtons onSubmit={handleSubmit} onClear={handleClear} />
        </div>
      </ComponentStyled>
    </FilterModalCommonStyles>
  );
};

const ComponentStyled = styled.div``;
