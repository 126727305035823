import React from 'react';

import { useQuery } from '@apollo/client';
import { GET_PRODUCT_CATEGORY_BY_TERM } from '../../../queries';
import { useNavigate, useParams } from 'react-router-dom';

import { IProduct } from '../../../types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import { FilterModalCommonStyles } from '../commonStyles';
import { FilterModalButtons } from '../../FilterModalButtons';
import { CategoriesStyled } from './styles';
import { useCategoriesFilter } from './useCategoriesFilter';

import { useConfiguration } from '../../../hooks/useConfiguration';
import { GET_LOCALIZED } from '../../../queries';

export const CategoriesFilterModal = ({
  productsBeforeFiltering,
  buttonsOff,
}: any) => {
  const {
    searchBar,
    setSearchBarInput,
    handleSubmit,
    handleClear,
    handleCheckboxes,
    checked,
  } = useCategoriesFilter({ productsBeforeFiltering });

  const { storeId = '', termId, harvestId = '' } = useParams();  
  
  const { LanguageVersion } = useConfiguration({ storeId, harvestId });

  const {data: translation} = useQuery(
    GET_LOCALIZED,
    {
      variables: {
        defaultLanguage: LanguageVersion,
      },
    }
  );

const { data, loading } = useQuery(
  GET_PRODUCT_CATEGORY_BY_TERM({
    termId: termId?.split('__')[0],
  })
);

const edges = data?.getEPogTermListing?.edges;

const categories = edges
    .map((edge: any) => edge?.node?.CategoriesFilterElements)
    .flat()

  if (categories.length > 1) {

    return (
      <FilterModalCommonStyles buttonsOff={buttonsOff}>
      <CategoriesStyled>
        <div>
          <h2>{translation?.getLocalization.CategoriesAreYouInterestedIn}</h2>

          <div className="search-bar">
            <FontAwesomeIcon icon={faSearch} />
            <input
              value={searchBar}
              onChange={(e) => setSearchBarInput(e.target.value)}
            />
            <FontAwesomeIcon
              icon={faTimesCircle}
              onClick={() => setSearchBarInput('')}
            />
          </div>
          <div className="checkboxes">
            <FormGroup>
            {categories && Object.values(categories).map((value, index: number) => {
                     return (<FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          name={categories[index].CategoryName}
                          checked={checked[categories[index].CategoryName]}
                          onChange={handleCheckboxes}
                        />
                      }
                      label={categories[index].CategoryName}
                    />)
                    } 
                )}
            </FormGroup>
          </div>
        </div>
        <div className="buttons">
          <FilterModalButtons onSubmit={handleSubmit} onClear={handleClear} />
        </div>
      </CategoriesStyled>
    </FilterModalCommonStyles>
    );

  } else {
  return (
    <FilterModalCommonStyles buttonsOff={buttonsOff}>
      <CategoriesStyled>
        <div>
          <h2>{translation?.getLocalization.CategoriesAreYouInterestedIn}</h2>

          <div className="search-bar">
            <FontAwesomeIcon icon={faSearch} />
            <input
              value={searchBar}
              onChange={(e) => setSearchBarInput(e.target.value)}
            />
            <FontAwesomeIcon
              icon={faTimesCircle}
              onClick={() => setSearchBarInput('')}
            />
          </div>

          <div className="checkboxes">
            <FormGroup>
              {productsBeforeFiltering ? (
                productsBeforeFiltering
                  .filter(
                    (product: IProduct, index: number, arr: IProduct[]) =>
                      product.SubCategory?.toLowerCase().includes(
                        searchBar.toLowerCase()
                      ) &&
                      index ===
                        arr.findIndex((item) => item.SubCategory === product.SubCategory)
                  )
                  .map((product: IProduct, index: number) => (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          name={product.SubCategory}
                          checked={checked[product.SubCategory]}
                          onChange={handleCheckboxes}
                        />
                      }
                      label={product.SubCategory}
                    />
                  ))
              ) : (
                <div></div>
              )}
            </FormGroup>
          </div>
        </div>
        <div className="buttons">
          <FilterModalButtons onSubmit={handleSubmit} onClear={handleClear} />
        </div>
      </CategoriesStyled>
    </FilterModalCommonStyles>
  );
              }
};
