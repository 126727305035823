import styled from '@emotion/styled';

export const Header = styled.h1`
  max-width: 743px;
  line-height: 29px;
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 24px;
  color: #000000;
  margin-top: 40px;
  margin-bottom: 24px;
  margin-left: 3px;
`;

export const PopularSectionsStyled = styled('div', {
  shouldForwardProp(propName) {
    return propName !== 'isLessThanSix' && propName !== 'CarouselWidth';
  },
})<{ isLessThanSix: boolean | undefined; CarouselWidth?: string }>`
  background: #ffffff;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 32px 30px 24px;

  @media (max-width: 768px) {
    padding: 15px;
  }
  & > div {
    display: flex;
    align-items: center;
  }

  .header {
    h1 {
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      color: #000000;
      margin-bottom: 24px;
    }

    span {
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: #000000;
      text-decoration-line: underline;
    }
  }

  .popular-item {
    display: flex;
    justify-content: ${({ isLessThanSix, CarouselWidth }) =>
        isLessThanSix && CarouselWidth === 'Full'
          ? 'flex-start'
          : 'space-between'}
      space-between;
    margin-top: 10px;
    gap: 15px;
    overflow-x: scroll;
    flex-wrap: ${({ CarouselWidth }) => CarouselWidth !== 'Full' && 'wrap'};

    @media (max-width: 1069px) {
      flex-wrap: nowrap;
      overflow-x: scroll;
    }

    & > div {
      cursor: pointer;
      margin-right: ${({ isLessThanSix }) => (isLessThanSix ? '50px' : '0')};
    }

    & > div > img {
      width: ${({ CarouselWidth }) =>
        CarouselWidth !== 'Full' ? '130px' : '160px'};
      height: auto;
      object-fit: contain;
    }

    & > div > p {
      margin-top: 7px;
      width: ${({ CarouselWidth }) =>
        CarouselWidth !== 'Full' ? '130px' : '160px'};
      text-align: center;
      height: 30px;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
  }
`;
