import styled from '@emotion/styled';

export const PdpReviewsStyled = styled.div`
  .sorting {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .sort-select {
      min-width: 168px;
    }

    .MuiSelect-select {
      padding: 4px 4px 4px 8px;
    }

    .MuiInputLabel-shrink {
      top: -25% !important;
    }

    .label-sort {
      top: -49%;
    }

    & > span {
      max-width: 647px;
      font-family: 'Montserrat';
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      display: flex;
      align-items: center;
      color: #000000;
    }
  }
`;

export const ButtonStyled = styled.button`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: ${({ theme }: any) => theme.productAddToCartBg};
  border: 1px solid ${({ theme }: any) => theme.productAddToCartBg};
  background-color: transparent;
  padding: 9px 16px;
  margin-bottom: 70px;
  margin-top: 46px;

  @media (max-width: 768px) {
    margin: 0;
  }
`;

export const ReviewStyled = styled.div`
  padding: 32px 40px;
  gap: 10px;
  background: #ffffff;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-bottom: 24px;
  margin-top: 41px;
  @media (max-width: 768px) {
    padding: 16px 20px;
    margin-top: 20px;
  }
  & > div:nth-of-type(2) {
    margin-bottom: 16px;
    max-width: 96px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
  }

  & > div:nth-of-type(3) {
    max-width: 663px;
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
  }

  & > div:nth-of-type(4) {
    margin-top: 24px;
    max-width: 28px;
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #afafaf;
  }

  .stars {
    display: flex;
    align-items: center;
    margin-bottom: 32px;
    & > span {
      margin-left: 5px;
    }
  }
`;
