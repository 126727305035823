import styled from '@emotion/styled';

export const ProductDescriptionStyled = styled.div`
.swiper {
  min-height:100px;
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    & > div:nth-child(2) {
      position: absolute;
      left: 5%;
      max-width: 50%;
      padding: 10px;
      background: #000000bd;
      color:white;
      * {
        margin:unset;
        color:white;
      }
    }
  }

  @media screen and (max-width:768px){
    .swiper-slide {
      text-align: center;
      display: flex;
      flex-direction:column;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      & > div:nth-child(n){
        position: static;
        max-width: unset;
        padding: 10px;
        background: none;
        color:black;
      }
    }
    .swiper-pagination{
      position:static;
    }
  }
  
}



  & > h3:nth-of-type(1) {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
  }

  & > h3:nth-of-type(2) {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
  }

  & > p {
    @media (max-width: 768px) {
    max-width: 744px;
    }

    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    margin-bottom: 32px;
  }

  & > div {
    @media (max-width: 768px) {
    max-width: 744px;
    }

    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    margin-left: 16px;
  }

  img {
    max-width: 100%;
  }

  table{
    width: 100%;
    height: 100%;
    margin: auto;
    display: block;
    overflow-y: hidden;
    border-spacing: 20px 0;
  }

  a{
    pointer-events: none;
    cursor: default;
    text-decoration: none;
    color: black;
  }

  td{
    padding: 5px 0;
  }
`