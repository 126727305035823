import React from 'react';

import styled from '@emotion/styled';
import { SimilarProducts } from '../SimilarProducts';
import { IProduct } from '../../types';

export const PopularProducts = ({
  products,
  className,
  title,
}: {
  products: IProduct[];
  className?: string;
  title: string | undefined;
}) => {
  return (
    <PopularProductsStyled>
      <div className="header">
        <div>
          <h1>{title}</h1>
        </div>
      </div>
      <div className="popular-item">
        <SimilarProducts
          className={className}
          products={products}
        ></SimilarProducts>
      </div>
    </PopularProductsStyled>
  );
};

const PopularProductsStyled = styled.div`
  background: #ffffff;

  .header {
    & > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    & > div > h1 {
      font-family: 'Montserrat';
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      color: #000000;
      margin-bottom: 24px;
    }
  }
`;
