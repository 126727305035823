import React from 'react';

import { AddButton, StepperComponent } from '..';

export const Stepper = ({
  AddPosition,
  newAmount,
  handleAddToCartChange,
}: any) => {
  return (
    <AddButton AddPosition={AddPosition}>
      <StepperComponent
        newAmount={newAmount}
        handleAddToCartChange={handleAddToCartChange}
      />
    </AddButton>
  );
};
