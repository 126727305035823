import React from 'react';
import DOMPurify from 'dompurify';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import SwiperCore, { Pagination, Navigation } from 'swiper';
import { ProductDescriptionStyled } from './styles';

const sanitizedData = (data: any) => ({
  __html: DOMPurify.sanitize(data, { FORBID_TAGS: ['style', 'script'] }),
});

const ProductDescription = ({ content, header }: { content: string, header: string }) => {
  const description = <div dangerouslySetInnerHTML={sanitizedData(content)}></div>;
  
  React.useEffect(() => {
    const swiperElements = document.querySelectorAll('.product-details-swiper');
    swiperElements.forEach((element) => {
      const swiperElement = element as HTMLElement;
      new SwiperCore(swiperElement, {
        slidesPerView: 1,
        spaceBetween: 30,
        loop: true,
        modules: [Pagination, Navigation],
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      });
    });
  }, [content]);

  return (
    <>
      <ProductDescriptionStyled>
        <h3>{header}</h3>
        {description}
      </ProductDescriptionStyled>
    </>
  );
}

export default ProductDescription;