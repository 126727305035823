import React from 'react';

import { IProduct } from '../../types';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';

import { Navigation } from 'swiper';
import { ProductCard } from '../ProductCard';
import { useParams } from 'react-router-dom';

import styled from '@emotion/styled';
import { ReactSVG } from 'react-svg';
import { normalizeNameForUrl } from '../../helpers';

export const SimilarProducts = ({
  products,
  className,
}: {
  products: IProduct[];
  className?: string;
}) => {
  const { categoryId, storeId, harvestId } = useParams();

  return (
    <SwiperStyled>
      <Swiper
        navigation={{
          nextEl: `.arrow-right` + className,
          prevEl: `.arrow-left` + className,
        }}
        modules={[Navigation]}
        className={className}
        breakpoints={{
          991: {
            slidesPerView: 4,
          },
          830: {
            slidesPerView: 3,
          },
          550: {
            slidesPerView: 2,
          },
          480: {
            slidesPerView: 1,
          },
        }}
        spaceBetween={24}
      >
        {products &&
          products.map((product: IProduct, index) => {

            const price = product.Price != 0
              ? product.Price
              : product.RegularPrice;
            const brand = product?.Brand
              ? product?.Brand
              : '';

            return (
              <SwiperSlide key={index}>
                <ProductCard
                  product={product}
                  url={
                    categoryId
                      ? `/${storeId}/${harvestId}/ip/${categoryId}/${
                          product?.id

                        }__${brand}__${normalizeNameForUrl(product?.Name)}_${price}`
                      : `/${storeId}/${harvestId}/ips/${
                          product?.id
                        }__${brand}__${normalizeNameForUrl(product?.Name)}_${price}`

                  }
                  onClick={
                    categoryId
                      ? `/${storeId}/${harvestId}/ip/${categoryId}/${
                          product?.id

                        }__${brand}__${normalizeNameForUrl(product?.Name)}_${price}`
                      : `/${storeId}/${harvestId}/ips/${
                          product?.id
                        }__${brand}__${normalizeNameForUrl(product?.Name)}_${price}`

                  }
                />
              </SwiperSlide>
            );
          })}
      </Swiper>
      <ReactSVG
        src="/files/generic/gallery-arrow.svg"
        className={`arrow-left${className} arrows`}
        height={40}
        width={40}
      />
      <ReactSVG
        src="/files/generic/gallery-arrow.svg"
        className={`arrow-right${className} arrows`}
      />
    </SwiperStyled>
  );
};

const SwiperStyled = styled.div`
  position: relative;
  background-color: #ffffff;

  padding: 0 max(20px, 3%);

  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */

  .swiper-slide {
    padding: 10px;
  }

  .swiper-button-disabled {
    visibility: hidden;
  }
  .arrows svg {
    cursor: pointer;

    height: 50px;
    width: 50px;

    @media (max-width: 768px) {
      height: 40px;
      width: 40px;
    }
  }

  & > div:nth-of-type(2) {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%) rotate(90deg);
    z-index: 1000;
  }
  & > div:last-of-type {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%) rotate(-90deg);
    z-index: 1000;
  }
`;
