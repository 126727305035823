import React from 'react';

import Stack from '@mui/material/Stack';
import Rating from '@mui/material/Rating';
import { IProduct } from '../../types';
import {
  BannerVideo,
  BrandContainer,
  Container,
  DescriptionContainer,
  Header,
  Paragraph,
  ProductContainer,
  ProductHeader,
} from './styles';
import { imgSrcByEnv } from '../../../config/imgSrcByEnv';
import { IAddProductButtonFunctionality, TCurrency } from '../../types/config';
import { useNavigate, useParams } from 'react-router-dom';
import { normalizeNameForUrl } from '../../helpers';
import { AddToCartButton } from '../AddToCartButton';

export const AdvBanner = ({
  TermBannerImage,
  TermBannerProducts,
  TermBannerSponsoredBy,
  TermBannerText,
  Currency,
  TermBannerVideo,
  TermBannerVideoPosition,
  className
}: {
  TermBannerImage: { fullpath: string };
  TermBannerProducts: IProduct[];
  TermBannerSponsoredBy: string;
  TermBannerText: string;
  AddProductButton?: string;
  Currency: TCurrency;
  TermBannerVideo?: string;
  TermBannerVideoPosition?: string;
  className?: string;
}) => {
  const { storeId = '', categoryId, harvestId = '' } = useParams();
  const navigate = useNavigate();
  const isVideoOnTop = TermBannerVideoPosition === 'top';
  return (<>
  {TermBannerVideo && isVideoOnTop &&
    <BannerVideo isVideoOnTop={isVideoOnTop} className={'promoBannerVideo'} src={`${imgSrcByEnv()}${TermBannerVideo}`} controls autoPlay muted loop playsInline />
  }
    <Container className={'promoBanner '+className}>
      <BrandContainer className={'promoBannerBrandContainer'}>
        <div className="brand-container">
            <img
              src={`${imgSrcByEnv()}${TermBannerImage?.fullpath}`}
              alt={TermBannerSponsoredBy}
            />
          <div>
            {TermBannerText && <Header>{TermBannerText}</Header>}
            <Paragraph>Sponsored by {TermBannerSponsoredBy}</Paragraph>
          </div>
        </div>
      </BrandContainer>
      <div className="products-container">
        {TermBannerProducts?.map((product: IProduct, index: number) => {
          const currency = Currency ? Currency : '$'
          const price = product.Price != 0 ? (
            <>
              {currency}
              {product.Price.toFixed(2)}{' '}
              <span className="oldPrice">
                {currency}
                {product.RegularPrice.toFixed(2)}
              </span>
            </>
          ) : (
            <>
              {currency}
              {product.RegularPrice.toFixed(2)}
            </>
          )
          const urlPrice = product.Price != 0 ? product.Price : product.RegularPrice

          let promoCode;
          if (product.URLPromoCode != null) {
            promoCode = '__promoCode-' + product.URLPromoCode;
          } else {
            promoCode = '__promoCode-';
          }
          const position = "";

          const brand = product?.Brand
            ? product?.Brand
            : '';

          const productRoute = categoryId
            ? `/${storeId}/${harvestId}/ip/${categoryId}/${
                product?.id

              }__${brand}__${normalizeNameForUrl(product?.Name)}}_${urlPrice}`
            : `/${storeId}/${harvestId}/ips/${
                product?.id
              }__${brand}__${normalizeNameForUrl(product?.Name)}_${urlPrice}`;

          return (
            <ProductContainer className={'promoBannerProduct'} key={index}>
              <div>
                <img
                  onClick={() => navigate(productRoute)}
                  src={`${imgSrcByEnv()}${
                    product?.Gallery[0]?.image?.fullpath
                  }`}
                  alt="product"
                />
                <AddToCartButton
                  type={IAddProductButtonFunctionality.text}
                  product={product}
                  url={
                    categoryId
                      ? `/${storeId}/${harvestId}/ip/${categoryId}/${
                          product?.id
                        }__${brand}__${normalizeNameForUrl(
                          product?.Name
                        )}}_${urlPrice}_${position}${promoCode}`
                      : `/${storeId}/${harvestId}/ips/${
                          product?.id
                        }__${brand}__${normalizeNameForUrl(
                          product?.Name
                        )}_${urlPrice}_${position}${promoCode}`
                  }
                />
              </div>
              <div>
                <ProductHeader onClick={() => navigate(productRoute)}>
                  {product?.Name}
                </ProductHeader>
                <DescriptionContainer>
                  {product.RROverall && (
                    <Stack spacing={1}>
                      <Rating
                        value={product.RROverall}
                        precision={0.5}
                        readOnly
                      />
                    </Stack>
                  )}
                  <p>
                    {price}
                  </p>
                </DescriptionContainer>
              </div>
            </ProductContainer>
          );
        })}
      </div>
    </Container>
    {TermBannerVideo && !isVideoOnTop &&
          <BannerVideo isVideoOnTop={isVideoOnTop} className={'promoBannerVideo'} src={`${imgSrcByEnv()}${TermBannerVideo}`} controls autoPlay muted loop playsInline />
    }
  </>);
};
