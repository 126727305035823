import React from 'react';

import { IProduct, Ireview } from '../../types';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
// import { mockReviews } from './mockReviews';
import { ButtonStyled, PdpReviewsStyled, ReviewStyled } from './styles';
import { useParams } from 'react-router-dom';
import { useConfiguration } from '../../hooks/useConfiguration';
import { GET_LOCALIZED } from '../../queries';
import { useQuery } from '@apollo/client';

export const PdpReviews = ({ product }: { product: IProduct }) => {
  const [sortBy, setSortBy] = React.useState('');
  const { storeId = '', termId, harvestId = '' } = useParams();

  const { LanguageVersion } = useConfiguration({ storeId, harvestId });

  const {data: translation} = useQuery(
    GET_LOCALIZED,
    {
      variables: {
        defaultLanguage: LanguageVersion,
      },
    }
  );

  const handleSorting = (reviews: Ireview[]) => {
    const reviewToSort = JSON.parse(JSON.stringify(reviews));
    if (sortBy === 'rating') {
      const sort = reviewToSort.sort(
        (a: Ireview, b: Ireview) => b.Rating - a.Rating
      );
      return sort;
    }
    return reviewToSort;
  };

  return (
    <PdpReviewsStyled>
      <div className="sorting">
        <span></span>

        <FormControl className="sort-select">
          <InputLabel className="label-sort">{translation?.getLocalization.SortBy}</InputLabel>
          <Select
            value={sortBy || ''}
            label={translation?.getLocalization.SortBy}
            onChange={(e) => setSortBy(e.target.value)}
          >
            <MenuItem value="">
              <em>{translation?.getLocalization.None}</em>
            </MenuItem>
            <MenuItem value="rating">{translation?.getLocalization.Rating}</MenuItem>
            {/* <MenuItem value={'date'}>Date</MenuItem> */}
          </Select>
        </FormControl>
      </div>
      <div className="reviews">
        {handleSorting(product.Reviews ? product.Reviews : []).map(
          (review: Ireview, index: number) => (
            <Review key={index} review={review} />
          )
        )}
      </div>
      <ButtonStyled>{translation?.getLocalization.SeeAllReviews}</ButtonStyled>
    </PdpReviewsStyled>
  );
};

export const Review = ({ review }: { review: Ireview }) => {

  const { storeId = '', termId, harvestId = '' } = useParams();

  const { LanguageVersion } = useConfiguration({ storeId, harvestId });

  const {data: translation} = useQuery(
    GET_LOCALIZED,
    {
      variables: {
        defaultLanguage: LanguageVersion,
      },
    }
  );

  return (
    <ReviewStyled>
      <div className="stars">
        <Stack spacing={1}>
          <Rating
            name="half-rating-read"
            value={review.Rating}
            precision={0.5}
            readOnly
          />
        </Stack>
        <span>{translation?.getLocalization.VerifiedPurchaser}</span>
      </div>
      <div>{review.Status}</div>
      <div>{review.Content}</div>
      <div>{review.Reviewer}</div>
    </ReviewStyled>
  );
};
