import styled from '@emotion/styled';
import { ModalTypes } from '../Modals/types';

export const FiltersMenuStyled = styled.div<{
  modalType: ModalTypes;
  isInlineFilters: boolean;
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: ${({ modalType }) =>
    modalType === ModalTypes.filtersMenu ? '100vh' : 'auto'};
  width: ${({ modalType }) =>
    modalType === ModalTypes.filtersMenu ? '40vw' : 'auto'};

  @media (max-width: 767px) {
    width: auto;
  }

  max-width: 420px;
  min-height: ${({ isInlineFilters }) => (isInlineFilters ? 'auto' : '100vh')};

  .filters {
    flex: 1;
    .filter {
      position: relative;
      z-index: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px 22px;
      span {
        letter-spacing: 0em;
        font-family: 'Montserrat';
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #000000;
        cursor: pointer;
      }
      svg {
        font-size: 12px;
        cursor: pointer;
      }
    }
  }

  .filter-header {
    position: sticky;
    background-color: #fff;
    z-index: 2;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid #000;
    padding: 24px;
    & > span {
      font-family: 'Montserrat';
      font-weight: 700;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0em;
    }
    & > svg {
      font-size: 12px;
      cursor: pointer;
    }
  }

  .filter-footer {
    @media (max-width: 767px) {
      display: block;
    }
    position: ${({ isInlineFilters }) =>
      isInlineFilters ? 'initial' : 'sticky'};
    z-index: 2;
    background-color: #fff;
    bottom: 0;
    left: 0;
    display: flex;
    width: 100%;
    border-top: 1px solid #000;
    padding: 24px;
    & > button {
      font-size: 14px;
      font-weight: 700;
      line-height: 22px;
      letter-spacing: 0em;
      padding-top: 9px;
      padding-bottom: 9px;
    }
    @media (max-width: 767px) {
      & > button:first-of-type {
        margin-bottom: 10px;
      }
    }
    & > button:first-of-type {
      margin-right: 24px;
    }
  }
`;
