import styled from '@emotion/styled';

export const FilterModalCommonStyles = styled.div<{ buttonsOff: boolean }>`
  padding: 15px;
  height: 100%;

  h2 {
    text-align: center;
    font-size: 1.4rem;
    font-weight: bolder;
    margin-bottom: 10%;
  }
  .buttons {
    opacity: ${({ buttonsOff }) => (buttonsOff ? 0 : 1)};
    height: ${({ buttonsOff }) => (buttonsOff ? 0 : 'auto')};
  }
`;
