import React from 'react';

import { AddButton, StepperComponent } from '..';

export const Text = ({
  AddPosition,
  newAmount,
  handleAddToCartChange,
  AddProductButton,
}: any) => {
  const [isClicked, setIsClicked] = React.useState(false);
  return !isClicked && !newAmount ? (
    <AddButton
      onClick={(e) => {
        e.preventDefault();
        handleAddToCartChange('plus');
        setIsClicked(!isClicked);
      }}
    >
      {AddProductButton}
    </AddButton>
  ) : (
    <AddButton AddPosition={AddPosition}>
      <StepperComponent
        newAmount={newAmount}
        handleAddToCartChange={handleAddToCartChange}
      />
    </AddButton>
  );
};
