import React from 'react';

import { useQuery } from '@apollo/client';
import { useLocation, useParams } from 'react-router-dom';
import {
  GET_PRODUCT_BY_ID,
  GET_PRODUCT_CATEGORY_BY_TERM,
  GET_SIMILAR_PRODUCTS,
} from '../../queries';
import { contentSquereCustomTag, artificialPageview, contentSquarePageEvent } from '../../helpers'

export const usePdpRoute = () => {
  const { productId, categoryId = '', storeId, harvestId } = useParams();
  const { pathname } = useLocation();

  const { data: categoryData, loading: categoryLoading } = useQuery(
    GET_PRODUCT_CATEGORY_BY_TERM({
      termId: productId?.split('__')[0],
    }),
    {
      skip: !categoryId || !categoryId.length || pathname.includes('ips'),
    }
  );

  const { data, loading } = useQuery(GET_PRODUCT_BY_ID, {
    variables: {
      getEPogId: Number(productId?.split('__')[0]),
    },
  });

  const { data: similiarData, loading: similarLoading } = useQuery(
    GET_SIMILAR_PRODUCTS,
    {
      variables: {
        fullpath: `/ePog Product Data/Products/${storeId}/${harvestId}`,
      },
    }
  );

  const [shuffledProducts, setShuffledproducts] = React.useState({
    shuffledSimilar: [],
    shuffledRecommended: [],
  });

  React.useEffect(() => {
    if (!similarLoading) {
      const products = !similarLoading && similiarData.getHarvest.children;

      const handleShuffling = (items: any) =>
        items
          .map((value: any) => ({ value, sort: Math.random() }))
          .sort((a: any, b: any) => a.sort - b.sort)
          .map(({ value }: any) => value)
          ?.filter((product: any) => product?.Name);

      const shuffledSimilar = handleShuffling(products).slice(0, 10);
      const shuffledRecommended = handleShuffling(products).slice(0, 10);

      setShuffledproducts({ shuffledSimilar, shuffledRecommended });
    }
  }, [similarLoading, similiarData]);

  React.useEffect(() => {
    contentSquereCustomTag(1, 'Path', window.location.pathname + window.location.hash.replace('#', '?__'), '')
    contentSquereCustomTag(2, 'pageType', 'PDP', '');
    artificialPageview(window.location.pathname + window.location.hash.replace('#', '?__'))
  }, [pathname])

  React.useEffect(() => {
      const timer = (!loading) ? setTimeout(() => window.scrollTo(0, 0), 5) : null;
      return () => {timer && clearTimeout(timer)};
  }, [pathname]);
  
  return {
    loading,
    categoryLoading,
    categoryData,
    data,
    categoryId,
    shuffledProducts,
  };
};
