import { IProduct } from './types';

export const handleFiltering = ({
  products,
  searchFilters,
}: {
  products: IProduct[];
  searchFilters: any;
}) => {
  let filteredArray = [...products];

  let tempArray: any;
  let midArray: any;
  let finArray: any;

  if (searchFilters.length) {
    searchFilters.forEach((searchFilter: any) => {
      if (searchFilter.name === 'Price') {
        const {
          userSelectedPriceFilterMin,
          userSelectedPriceFilterMax,
          productDatabaseMinPrice,
          productDatabaseMaxPrice,
        } = searchFilter;

        const min = userSelectedPriceFilterMin || productDatabaseMinPrice || 0;
        const max = userSelectedPriceFilterMax || productDatabaseMaxPrice;

        filteredArray = filteredArray.filter(({ RegularPrice, Price }) => {
          const price = RegularPrice ? RegularPrice : Price;
          return price >= min && price <= max;
        });
      }

      if (searchFilter.name === 'Brand') {
        const brandsToFilter = Object.entries(searchFilter.brands)
          .map(([key, value]) => value && key)
          .filter((brand: any) => typeof brand !== 'boolean');

        if (brandsToFilter.length) {
          filteredArray = filteredArray.filter((product: IProduct) =>
            brandsToFilter.includes(product.Brand)
          );
        }
      }

      if (searchFilter.name === 'Rating') {
        if (searchFilter.value) {
          filteredArray = filteredArray.filter(
            (product: IProduct) => product.RROverall >= searchFilter.value
          );
        }
      }

      if (searchFilter.name === 'Variants') {
        if (searchFilter.value) {
          if (searchFilter.value === 'yes') {
            filteredArray = filteredArray.filter((product: IProduct) => {
              const byTypeArr: any = product?.VariantsByType?.flat();
              return product?.Variants?.length > 0 || byTypeArr?.length > 0;
            });
          } else {
            filteredArray = filteredArray.filter((product: IProduct) => {
              const byTypeArr: any = product?.VariantsByType?.flat();
              return !product?.Variants?.length && !byTypeArr?.length;
            });
          }
        }
      }

      if (searchFilter.name === 'Categories') {
        const categoriesToFilter = Object.entries(searchFilter.categories)
          .map(([key, value]) => value && key)
          .filter((SubCategory: any) => typeof SubCategory !== 'boolean');

        if (categoriesToFilter.length) {
          filteredArray = filteredArray.filter((product: IProduct) =>
          categoriesToFilter.includes(product.SubCategory)
          );
        }
      }

      if (searchFilter.name === 'Special Offers') {
        const specialOffersToFilter = Object.entries(searchFilter.promo)
          .map(([key, value]) => value && key)
          .filter((PromoType: any) => typeof PromoType !== 'boolean');

        if (specialOffersToFilter.length) {
          filteredArray = filteredArray.filter((product: IProduct) =>
          specialOffersToFilter.includes(product.PromoType)
          );
        }
      }

      if (searchFilter.name.includes('Custom_')) {

        if (searchFilter.checked) {
          if (searchFilter.value) {
            tempArray = products.filter((product: IProduct) =>{
              if (product?.CustomFilterValues != null) { 
                var arr = [];
                for(var i = 0; i < product?.CustomFilterValues.length; i++)
                  {
                    arr.push(product?.CustomFilterValues[i].AdditionalFilterValue);
                  }
                return arr.includes(searchFilter.value);
              }
          });
          }
        }
      }
      midArray = tempArray;

      if (tempArray && tempArray.length >= 1) { 
        finArray = Array.from(new Set(tempArray.concat(finArray)));
      }

    });
  }

  if (finArray && finArray.length >= 1) { 
    filteredArray = finArray;
  }

  filteredArray = filteredArray.filter( Boolean );

  return filteredArray;
};

export const handleSorting = ({
  products,
  productsSorting,
}: {
  products: IProduct[];
  productsSorting: any;
}) => {
  const sortedArray = [...products];

  if (productsSorting.length) {
    productsSorting.forEach((productSort: any) => {
      if (productSort?.value?.includes('Price')) {
        sortedArray?.sort(
          (
            { Price: aPrice, RegularPrice: aRegularPrice },
            { Price: bPrice, RegularPrice: bRegularPrice }
          ) => {
            const priceA = aRegularPrice ? aRegularPrice : aPrice;
            const priceb = bRegularPrice ? bRegularPrice : bPrice;
            return productSort.value === 'Price:low>high'
              ? priceA - priceb
              : priceb - priceA;
          }
        );
      }

      if (productSort?.value?.includes('Rating')) {
        sortedArray?.sort(({ RROverall: aRating }, { RROverall: bRating }) => {
          return productSort.value === 'Rating:low>high'
            ? aRating - bRating
            : bRating - aRating;
        });
      }

      if (productSort?.value?.includes('Alphabet')) {
        sortedArray?.sort(({ key: aName }, { key: bName }) => {
          return productSort.value === 'Alphabet:A>Z'
            ? aName.localeCompare(bName)
            : -1 * aName.localeCompare(bName);
        });
      }
    });
  }
  return sortedArray;
};

type Review = {
  rating: string;
};

export const sortByRating = (reviews: Review[]) => {
  let reviewsByRating = {
    5: 0,
    4: 0,
    3: 0,
    2: 0,
    1: 0,
  };

  reviews.forEach((review) => {
    const reviewNumber = parseFloat(review.rating);
    if (reviewNumber >= 1 && reviewNumber < 2) {
      reviewsByRating[1] = reviewsByRating[1] + 1;
    }
    if (reviewNumber >= 2 && reviewNumber < 3) {
      reviewsByRating[2] = reviewsByRating[2] + 1;
    }
    if (reviewNumber >= 3 && reviewNumber < 4) {
      reviewsByRating[3] = reviewsByRating[3] + 1;
    }
    if (reviewNumber >= 4 && reviewNumber < 5) {
      reviewsByRating[4] = reviewsByRating[4] + 1;
    }
    if (reviewNumber === 5) {
      reviewsByRating[5] = reviewsByRating[5] + 1;
    }
  });
  return reviewsByRating;
};

export const normalizeNameForUrl = (name: string|null) => {
    if (!name) return '';
    return name
        .normalize('NFD')
        .replaceAll(' ', '_')
        .replace(/[^a-zA-Z0-9_ ]/g, '');
}

export const contentSquereCustomTag = (index: any, name: string, value: string, scope: string|null) => {
  window._uxa = window._uxa || [];
  window._uxa.push(['setCustomVariable', index, name, value]);
  //console.log('customVar sent; index: ' + index + '; name: ' + name + '; value: ' + value + '; scope: ' + scope + ';');
}

export const contentSquarePageEvent = (eventName: string) => {
  window._uxa.push(['trackPageEvent', eventName]);
  //console.log('pageEvent sent; eventName: ' + eventName + ';')
}

export const contentSquareDynamicEvent = (key: string, value: string) => {
  window._uxa.push(["trackDynamicVariable", {key: key, value: value}]);
  //console.log('pageEvent sent; key: ' + key + '; value: ' + value + ';')
}

export const dynataAddToCart = (url: any) => {
//http://localhost:3000/Walmart/Diapers%20A_old/ips/5018__Huggies__Huggies_Little_Snugglers_Comfortable_and_LatexFree_Diapers_Size_1_168_Count_65.8_cartadd
  //console.log('{qid: "qURLs", value: "https://generic.e-pog.com' + url + '"}')
  sendMessage('{qid: "qURLs", value: "https://generic.e-pog.com' + url + '"}');
}

export const cutOffMessage = () => {
  //http://localhost:3000/Walmart/Diapers%20A_old/ips/5018__Huggies__Huggies_Little_Snugglers_Comfortable_and_LatexFree_Diapers_Size_1_168_Count_65.8_cartadd
    //console.log('{qid: "qURLs", value: "https://generic.e-pog.com' + url + '"}')
    if (window.cmixMessage == "buttonClick") {
      sendMessage('{qid: "qURLs", value: "CHECKOUT"}');
    }
    if (window.cmixMessage == "productNotFound") {
      sendMessage('{qid: "qURLs", value: "PRODUCT_NOT_FOUND"}');
    }
}

export const sendCustomMessage = (message: string) => {
    sendMessage('{qid: "qURLs", value: "' + message + '"}');
}

export const artificialPageview = (path: string) => {
  if (window.csSent == false) {
    window._uxa = window._uxa || [];
    window._uxa.push(['setPath', window.location.pathname+window.location.hash.replace('#','?__')]);
    var mt = document.createElement("script"); mt.type = "text/javascript"; mt.async = true;
    mt.src = "//t.contentsquare.net/uxa/55cf2524ac84d.js";
    document.getElementsByTagName("head")[0].appendChild(mt);
    window.csSent = true;
    //sendMessage('{qid: "qURLs", value: "https://generic.e-pog.com' + path + '"}');
  } else {
    window._uxa.push(['trackPageview', path]);
    sendMessage('{qid: "qURLs", value: "https://generic.e-pog.com' + path + '"}');
    //console.log('artificialPageview sent; path: ' + path + ';');
  }
}

//sendMessage({qid: "qURLs",value: "https://generic.e-pog.com/Sams%20Club/Toilet%20Paper%20C/ips/15227__POM__POM_Bath_Tissue_Septic_Safe_2Ply_White_473_sheetsroll_45_rolls_27.98_4"})
export const sendMessage = (msg: string) => {
    window.parent.postMessage(msg.replaceAll('%20', ' '), '*');
    console.log('message sent: ' + msg.replaceAll('%20', ' '));
}

export const setWindowVars = () => {
  window.finishIt = false;
  window.triggerPath = '';
  window.cmixMessage = '';
}
