import React from 'react';

import styled from '@emotion/styled';

export const DeliveryDate = ({
  text,
  className,
}: {
  text: string;
  className: string;
}) => {
  const date = new Date();
  const newDate =
    date && new Date(date.setDate(date.getDate() + 5)).toString().split(' ');
  return (
    <DeliveryDateStyled>
      {newDate && (
        <div className={className + ' arrives-by'}>
          <span>
            {text} {newDate[0]}, {newDate[1]} {newDate[2]}
          </span>
        </div>
      )}
    </DeliveryDateStyled>
  );
};

const DeliveryDateStyled = styled.div`
  font-family: lato, sans-serif;
  font-size: 22px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  text-transform: capitalize;

  .arrives-by {
    margin: 24px 0 10px;
    font-size: 1.3rem;
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 14px;
    line-height: 15px;
    color: #000000;

    & > span > p {
      max-width: 33px;
      height: 15px;
      font-family: 'Montserrat';
      font-weight: 400;
      font-size: 14px;
      line-height: 15px;
      text-decoration-line: underline;
      color: #000000;
      margin-top: 8px;
    }
  }

  .header {
    margin: 0;
    max-width: 744px;
    background: #dbdbdb;
    opacity: 0.8;
    border-radius: 10px 10px 0px 0px;
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 20px;
    color: #000000;
    line-height: 35px;
    padding: 20px 0 20px 30px;
  }

  .content {
    height: 25px;
  }
`;
