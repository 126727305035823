import React from 'react';

import { Input, SearchInputStyled } from './styles';
import { useParams } from 'react-router-dom';
import { useConfiguration } from '../../hooks/useConfiguration';
import { GET_LOCALIZED } from '../../queries';
import { useQuery } from '@apollo/client';

export const SearchInput = ({
  handleSearchSubmit,
  handleSearchSuggestions,
  searchKeyword,
}: {
  handleSearchSubmit: (value: string) => void;
  handleSearchSuggestions: (value: string) => void;
  searchKeyword: string;
}) => {
  const [value, setValue] = React.useState('');

  const { storeId = '', termId, harvestId = '' } = useParams();

  const { LanguageVersion } = useConfiguration({ storeId, harvestId });

  const {data: translation} = useQuery(
    GET_LOCALIZED,
    {
      variables: {
        defaultLanguage: LanguageVersion,
      },
    }
  );

  React.useEffect(() => {
    const handleEnterPush = (e: any) => {
      if (e.key === 'Enter') {
        handleSearchSubmit(value);
      }
    };
    document.addEventListener('keydown', handleEnterPush);

    return () => document.removeEventListener('keydown', handleEnterPush);
  });

  return (
    <SearchInputStyled>
      <Input
        onChange={(e) => {
          setValue(e.target.value);
          handleSearchSuggestions(e.target.value);
        }}
        placeholder={translation?.getLocalization.SearchInStore}
        value={searchKeyword}
        disabled={true}
      ></Input>
      <img
        src="/files/generic/img/search.svg"
        alt={translation?.getLocalization.SearchInStore}
        onClick={() => handleSearchSubmit(value)}
        style={{ pointerEvents: "none" }}
      />
    </SearchInputStyled>
  );
};
