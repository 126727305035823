const env = process.env.REACT_APP_ENV;
const assetsUrl = process.env.REACT_APP_ASSETS_URL;

export const imgSrcByEnv = () => {
  return assetsUrl;
  /*if (env === 'prod') {
    return 'https://pimcore.e-pog.com';
  } else {
    return `https://pimcore.${process.env.REACT_APP_ENV}.e-pog.com`;
  }*/
};
