import React from 'react';

import { AddButton, StepperComponent } from '..';

export const TextAndStepper = ({
  AddPosition,
  handleAddToCartSubmit,
  handleIncreaseByOne,
  handleDecreaseByOne,
  newAmount,
  AddProductButton,
}: any) => {
  return (
    <AddButton AddPosition={AddPosition}>
      <StepperComponent
        handleIncreaseByOne={handleIncreaseByOne}
        handleDecreaseByOne={handleDecreaseByOne}
        newAmount={newAmount}
        isTextAndStepper={true}
      />

      <span
        onClick={(e) => {
          handleAddToCartSubmit(e);
        }}
      >
        {AddProductButton}
      </span>
    </AddButton>
  );
};
