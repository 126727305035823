import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { AddPositionEnum, ProductCardBorderEnum,  } from '../../types/config';



export const CustomLabelStyledDiv = styled.div<{
  LabelColor?: string;
  LabelTextColor?: string;
  LabelPosition: string;
}>`
  // font-weight: bold;
  // margin: 10px;
  // padding: 5px;
  // border: 1px solid ${({LabelColor}) => LabelColor != '' ? LabelColor : '' };
  // border-radius: 3px;
  // width: max-content;
  // background-color: ${({LabelColor}) => LabelColor != '' ? LabelColor : '' };
  // color: ${({LabelTextColor}) => LabelTextColor != '' ? LabelTextColor : '' };

  // margin-left:0;


  font-weight: bold;
  margin: 10px;
  padding: 5px;
  border: 1px solid ${({LabelColor}) => 
  LabelColor != '' ? LabelColor : '' };
  border-radius: 3px;
  position: absolute;
  top: 0;
  background-color: ${({LabelColor}) => LabelColor != '' ? LabelColor : '' };
  ${({ LabelPosition }) => LabelPosition === 'Left' ? 'left: 0;' : 'right: 0;'}

  color: ${({LabelTextColor}) => LabelTextColor != '' ? LabelTextColor : '' };


`

export const ProductContainer = styled.div<{
  ProductCardBorder?: ProductCardBorderEnum;
  SponsoredPosition?: string;
}>`
  padding: 16px;
  max-width: 360px;
  max-height: 560px;
  background: #ffffff;
  box-shadow: ${({ ProductCardBorder }) =>
    ProductCardBorder === ProductCardBorderEnum.shadow &&
    '0px 0px 15px rgba(0, 0, 0, 0.1)'};
  border: ${({ ProductCardBorder }) =>
    ProductCardBorder === ProductCardBorderEnum.border &&
    '1px solid rgba(0, 0, 0, 0.1)'};
  border-radius: 10px;
  position: relative;
  margin: 10px 0;

  @media (max-width: 768px) {
    margin: 0 auto;
  }

  & > div:nth-of-type(1) > img {
    height: 176px;
    width: 100%;
    object-fit: contain;
    display: block;
    margin: 0 auto;
  }

  & > img:nth-of-type(1) {
    position: absolute;
    top: 2%;
    left: 85%;
  }

  & > div:nth-of-type(2) {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0%;
    left: 0%;
    margin-top: 10px;

    & > img:nth-of-type(2) {
      position: absolute;
      top: 120%;
      left: 0%;
    }
  }

  .sponsored {
    color: #666666;
    padding: 10px 0;
    ${({ SponsoredPosition }) =>
    SponsoredPosition === 'Left' ? 
    'text-align: left;' : 'text-align: right;'}
  }

  .additional {
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    margin: 5px 0;
  }
`;

export const LinkStyled = styled(Link)`
  text-decoration: none;
  color: #000000;
`;

export const DescriptionContainer = styled.div<{ AddPosition?: String }>`
  position: relative;
  padding: 16px 0 24px 0;
  p {
    font-family: 'Montserrat';
  }

  div {
    text-align: ${({ AddPosition }) =>
      AddPosition === AddPositionEnum.Left && 'left'};
    text-align: ${({ AddPosition }) =>
      AddPosition === AddPositionEnum.Right && 'right'};
    text-align: ${({ AddPosition }) =>
      AddPosition === AddPositionEnum.EmptyString && 'center'};
  }

  .name {
    max-height: 65px;
    margin-top: 16px;
    height: 65px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }

  .brand {
    margin-top: 16px;
    max-height: 24px;
    height: 24px;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    text-decoration-line: underline;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }

  .rating {
    padding: 10px 0;
    display: flex;
    align-items: center;
    & > span {
      margin-left: 5px;
    }
  }

  .price {
    margin-top: 16px;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    & > span {
      color: #777;
      font-weight: 400;
    }
  }

  .oldPrice {
    font-size: 12px;
    color: #999999;
    text-decoration: line-through
  }

  .promo{
    height: 115px;
    & > img {
      width: 100%;
    }
    & > p {
      font-size: 13px;
      font-weight: 450;
      line-height: 24px;
    }
  }
`;
