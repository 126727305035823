import React from 'react';

import styled from '@emotion/styled';

import { useParams } from 'react-router-dom';

import Dialog from '@mui/material/Dialog';
import { ModalTypes } from './types';
import { useAppContext } from '../../context';
import { FiltersMenu } from '../FiltersMenu';
import { actions } from '../../context/actions';
import { useConfiguration } from '../../hooks/useConfiguration';
import { BrandsFilterModal } from '../Filters/BrandsFilter';
import { CategoriesFilterModal } from '../Filters/CategoriesFilter';
import { VariantsFilter } from '../Filters/VariantsFilter';
import { SpecialOffersFilterModal } from '../Filters/SpecialOffersFilter';
import { PriceRangeFilter } from '../Filters/PriceRangeFilter';
import { Sorting } from '../Filters/Sorting';
import { RatingFilter } from '../Filters/RatingFilter';

export const ModalComponent = () => {
  const {
    value: { isModal, modalType, modalContent },
    dispatch,
  } = useAppContext();

  const { storeId = '', harvestId = '' } = useParams();

  const { FilterPosition } = useConfiguration({ storeId, harvestId });

  const handleClose = () =>
    dispatch({
      type: actions.TOGGLE_MODAL,
      payload: { isModal: false, modalContent: undefined },
    });

  const modalSelector = () => {
    switch (modalType as ModalTypes) {
      case ModalTypes.filtersMenu:
        return (
          <FiltersMenu
            productsBeforeFiltering={modalContent.productsBeforeFiltering}
          />
        );

      case ModalTypes.priceRange:
        return (
          <PriceRangeFilter
            productsBeforeFiltering={modalContent.productsBeforeFiltering}
          />
        );

      case ModalTypes.brands:
        return (
          <BrandsFilterModal
            productsBeforeFiltering={modalContent.productsBeforeFiltering}
          />
        );

      case ModalTypes.sorting:
        return <Sorting />;

      case ModalTypes.rating:
        return <RatingFilter />;

      case ModalTypes.variants:
        return <VariantsFilter />;

      case ModalTypes.specialOffers:
        return (
          <SpecialOffersFilterModal
            productsBeforeFiltering={modalContent.productsBeforeFiltering}
          />
        );

      case ModalTypes.categories:
        return (
          <CategoriesFilterModal
            productsBeforeFiltering={modalContent.productsBeforeFiltering}
          />
        );

      default:
        return false;
    }
  };

  return (
    <DialogStyled
      open={isModal}
      onClose={handleClose}
      modalType={modalType}
      FilterPosition={FilterPosition}
    >
      {modalSelector()}
    </DialogStyled>
  );
};

const DialogStyled = styled(Dialog, {
  shouldForwardProp(propName) {
    return propName !== 'modalType' && propName !== 'FilterPosition';
  },
})<{
  modalType: ModalTypes;
  FilterPosition?: string;
}>`
  .MuiDialog-container {
    display: flex;
    justify-content: ${({ modalType, FilterPosition }) => {
      if (modalType === ModalTypes.filtersMenu) {
        if (FilterPosition === 'Left') {
          return 'flex-start';
        } else {
          return 'flex-end';
        }
      }

      return 'center';
    }};
  }

  .MuiDialog-paper {
    max-height: ${({ modalType }) =>
      modalType === ModalTypes.filtersMenu && '100vh'};
    margin: ${({ modalType }) => modalType === ModalTypes.filtersMenu && '0'};
    @media (max-width:768px){
      min-width: ${({ modalType }) => modalType === ModalTypes.filtersMenu && '200px'}};
      max-width: ${({ modalType }) => modalType === ModalTypes.filtersMenu && '55%'}};
      z-index: ${({ modalType }) => modalType === ModalTypes.filtersMenu && '9999'}};
    }
  }
`;
