import React from 'react';

import { useAppContext } from '../../../context';
import { actions } from '../../../context/actions';
import { IProduct } from '../../../types';

export const useBrandsFilter = ({
  productsBeforeFiltering,
}: {
  productsBeforeFiltering: IProduct[];
}) => {
  const {
    value: { searchFilters },
    dispatch,
  } = useAppContext();

  const brandFilter = searchFilters.find(
    (filter: any) => filter.name === 'Brand'
  );

  const intitialCheckedState = productsBeforeFiltering.reduce(
    (obj: any, product: IProduct) => {
      return { ...obj, [product.Brand]: false };
    },
    {}
  );

  const [searchBar, setSearchBarInput] = React.useState('');
  const [checked, setChecked] = React.useState<any>(
    brandFilter?.brands || intitialCheckedState
  );

  const handleSubmit = () => {
    dispatch({
      type: actions.ADD_SEARCH_FILTER,
      payload: { name: 'Brand', brands: checked },
    });

    dispatch({
      type: actions.TOGGLE_MODAL,
      payload: { isModal: false, modalContent: null },
    });
  };

  const handleClear = () => {
    setChecked(intitialCheckedState);
    dispatch({
      type: actions.REMOVE_SEARCH_FILTER,
      payload: {
        name: 'Brand',
        brands: intitialCheckedState,
      },
    });
  };

  const handleCheckboxes = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked({ ...checked, [event.target.name]: event.target.checked });
  };

  return {
    searchBar,
    setSearchBarInput,
    handleSubmit,
    handleClear,
    handleCheckboxes,
    checked,
  };
};
