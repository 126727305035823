import React from 'react';

import styled from '@emotion/styled';

import Slider from '@mui/material/Slider';

import { FilterModalCommonStyles } from '../commonStyles';
import { FilterModalButtons } from '../../FilterModalButtons';
import { useAppContext } from '../../../context';
import { actions } from '../../../context/actions';
import { IProduct } from '../../../types';
import { useConfiguration } from '../../../hooks/useConfiguration';
import { useParams } from 'react-router-dom';
import { GET_LOCALIZED } from '../../../queries';
import { useQuery } from '@apollo/client';

export const PriceRangeFilter = ({
  buttonsOff,
  productsBeforeFiltering,
}: any) => {
  const {
    value: { searchFilters },
    dispatch,
  } = useAppContext();
  const { storeId = '', harvestId = '' } = useParams();
  const { Currency, LanguageVersion } = useConfiguration({ storeId, harvestId });

  const {data: translation} = useQuery(
    GET_LOCALIZED,
    {
      variables: {
        defaultLanguage: LanguageVersion,
      },
    }
  );

  const products = [...productsBeforeFiltering];

  const sorted = products?.sort(
    (a: IProduct, b: IProduct) => b.RegularPrice - a.RegularPrice
  );

  const priceFilter = searchFilters.find(
    (filter: any) => filter.name === 'Price'
  );

  const productDatabaseMaxPrice = Math.ceil(sorted[0].RegularPrice);
  const productDatabaseMinPrice = Math.floor(
    sorted[sorted.length - 1].RegularPrice
  );

  const userSelectedPriceFilterMin = priceFilter?.userSelectedPriceFilterMin;
  const userSelectedPriceFilterMax = priceFilter?.userSelectedPriceFilterMax;

  const [useSelectedPriceFilter, setUseSelectedPriceFilter] = React.useState([
    userSelectedPriceFilterMin || productDatabaseMinPrice,
    userSelectedPriceFilterMax || productDatabaseMaxPrice,
  ]);

  const handleChange = (event: any, newValue: any) => {
    setUseSelectedPriceFilter(newValue);
  };

  const handleSubmit = () => {
    const currentPriceFilter = searchFilters.find(
      (filter: any) => filter.name === 'Price'
    );
    if (
      productDatabaseMinPrice !== useSelectedPriceFilter[0] ||
      productDatabaseMaxPrice !== useSelectedPriceFilter[1]
    )
      dispatch({
        type: actions.ADD_SEARCH_FILTER,
        payload: {
          ...currentPriceFilter,
          name: 'Price',
          userSelectedPriceFilterMin: useSelectedPriceFilter[0],
          userSelectedPriceFilterMax: useSelectedPriceFilter[1],
          isFiltered: true,
        },
      });

    dispatch({
      type: actions.TOGGLE_MODAL,
      payload: { isModal: false, modalContent: null },
    });
  };

  const handleClearPriceFilter = () => {
    setUseSelectedPriceFilter([
      Math.floor(sorted[sorted.length - 1].RegularPrice),
      Math.ceil(sorted[0].RegularPrice),
    ]);

    dispatch({
      type: actions.REMOVE_SEARCH_FILTER,
      payload: {
        name: 'Price',
        productDatabaseMaxPrice: sorted[0].RegularPrice,
        productDatabaseMinPrice: sorted[sorted.length - 1].RegularPrice,
      },
    });
  };

  const value = [useSelectedPriceFilter[0], useSelectedPriceFilter[1]];
  const currency = Currency ? Currency : '$';
  return (
    <FilterModalCommonStyles buttonsOff={buttonsOff}>
      <PriceRangeStyled>
        <div>
          <h2>{translation?.getLocalization.YourPriceRange}</h2>

          <p>
            {currency}
            {value[0]} - {currency}
            {value[1]}+
          </p>

          <div className="slider">
            <Slider
              value={value}
              onChange={handleChange}
              min={productDatabaseMinPrice}
              max={productDatabaseMaxPrice}
              marks={[
                {
                  value: productDatabaseMinPrice,
                  label: productDatabaseMinPrice,
                },
                {
                  value: productDatabaseMaxPrice,
                  label: productDatabaseMaxPrice,
                },
              ]}
            />
          </div>

          <p>${(productDatabaseMaxPrice / 2).toFixed(0)}</p>
        </div>
        <div className="buttons">
          <FilterModalButtons
            onSubmit={handleSubmit}
            onClear={handleClearPriceFilter}
          />
        </div>
      </PriceRangeStyled>
    </FilterModalCommonStyles>
  );
};

const PriceRangeStyled = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;

  & > div:first-of-type {
    flex: 1;
  }
  h2 {
    font-size: 1.7rem;
    font-weight: 600;
  }

  p:first-of-type {
    margin-top: 15%;
    text-align: center;
    font-weight: 600;
    font-size: 1.8rem;
  }
  p:last-of-type {
    text-align: center;
  }

  .slider {
    width: 85%;
    margin: 0 auto;

    .MuiSlider-root {
      margin-bottom: 0;
    }
    .MuiSlider-rail,
    .MuiSlider-track {
      height: 1px;
    }
  }
`;
