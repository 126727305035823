import React from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { imgSrcByEnv } from '../../../config/imgSrcByEnv';
import { PopularSectionsStyled } from './styles';
import { useConfiguration } from '../../hooks/useConfiguration';
import { useQuery } from '@apollo/client';
import { GET_LOCALIZED } from '../../queries'

interface Icategory {
  key: string;
  id: string;
  fullpath: string;
  TermImage: {
    fullpath: string;
  };
}

export const PopularSections = ({
  CarouselWidth,
  categories,
}: {
  CarouselWidth?: string;
  categories?: { node: Icategory }[];
}) => {
  const navigate = useNavigate();
  const { storeId = '', harvestId = '' } = useParams();
  const { LanguageVersion } = useConfiguration({ storeId, harvestId });

  const {data: translation} = useQuery(
    GET_LOCALIZED,
    {
      variables: {
        defaultLanguage: LanguageVersion,
      },
    }
  );

  return (
    <PopularSectionsStyled
      isLessThanSix={categories && categories?.length < 6}
      CarouselWidth={CarouselWidth}
    >
      <div className="header">
        <div>
          <h1>{translation?.getLocalization.PopularSections}</h1>
          <span>View all</span>
        </div>
      </div>
      <div className="popular-item">
        {CarouselWidth === 'Full'
          ? categories
              ?.slice(0, 6)
              .map(({ node: { key, id, TermImage } }, index) => (
                <div
                  onClick={() =>
                    navigate(
                      `/${storeId}/${harvestId}/category/${id}__${key.replaceAll(
                        ' ',
                        '_'
                      )}`
                    )
                  }
                  key={index}
                >
                  <img
                    src={
                      TermImage?.fullpath
                        ? `${imgSrcByEnv()}${TermImage?.fullpath}`
                        : '/files/generic/img/square.svg'
                    }
                    alt="square"
                  />
                  <p>{key}</p>
                </div>
              ))
          : categories
              ?.slice(0, 4)
              .map(({ node: { key, id, TermImage } }, index) => (
                <div
                  onClick={() =>
                    navigate(
                      `/${storeId}/${harvestId}/category/${id}__${key.replaceAll(
                        ' ',
                        '_'
                      )}`
                    )
                  }
                  key={index}
                >
                  <img
                    src={
                      TermImage?.fullpath
                        ? `${imgSrcByEnv()}${TermImage?.fullpath}`
                        : '/files/generic/img/square.svg'
                    }
                    alt="square"
                  />
                  <p>{key}</p>
                </div>
              ))}
      </div>
    </PopularSectionsStyled>
  );
};
