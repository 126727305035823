import React from 'react';

import { ProductContainer, DescriptionContainer, LinkStyled, CustomLabelStyledDiv } from './styles';
import { IProduct } from '../../types';
import { useConfiguration } from '../../hooks/useConfiguration';
import { useNavigate, useParams } from 'react-router-dom';
import {
  IAddProductButtonFunctionality,
  IOptionButtonVisibilty,
  IProductField,
  ProductFieldName,
} from '../../types/config';
import { Rating, Stack } from '@mui/material';
import { AddButton, AddToCartButton } from '../AddToCartButton';
import { imgSrcByEnv } from '../../../config/imgSrcByEnv';

export const ProductCard = ({
  product,
  onClick,
  url
}: {
  product: IProduct;
  onClick: string;
  url: string;
}) => {
  const navigate = useNavigate();

  const { storeId = '', harvestId = '' } = useParams();
  const {
    Currency,
    ProductFields,
    AddPosition,
    ProductCardBorderType,
    OptionButtonVisibility,
    AddProductButtonFunctionality,
    SponsoredPosition
  } = useConfiguration({
    storeId,
    harvestId,
  });

  const currency = Currency ? Currency : '$';
  
  const pricePerUnit = product.PricePerUnit && product.PricePerUnit != "0" ? " (" + product.PricePerUnit + ")" : "";

  return (
    <LinkStyled to={onClick}>
      <ProductContainer ProductCardBorder={ProductCardBorderType} SponsoredPosition={SponsoredPosition}>
        <div>
          <img
            src={`${imgSrcByEnv()}${
              product?.Gallery && product?.Gallery[0]?.image?.fullpath
            }`}
            alt="gallery fullpath"
          />
          {product.LabelText ? 
           <CustomLabelStyledDiv LabelColor={product.LabelColorCode} LabelTextColor={product.LabelTextColor} LabelPosition={product.LabelPosition}>
            {product.LabelText}
            </CustomLabelStyledDiv>
           : ''}
        </div>

        <div></div>

        <DescriptionContainer>
          {ProductFields &&
            Object.values(ProductFields).map(
              (value: IProductField, index: number) => {
                const price = product?.Price != 0
                  ? product?.Price?.toFixed(2)
                  : product?.RegularPrice?.toFixed(2);

                  if (product?.Sponsored && value.Name === ProductFieldName.Sponsored) {
                    return (
                      <p className="sponsored" key={index}>
                        Sponsored
                      </p>
                    );
                  }  

                if (product?.Brand && value.Name === ProductFieldName.Brand) {
                  return (
                    <p className="brand" key={index}>
                      {product?.Brand}
                    </p>
                  );
                }
                if (value.Name === ProductFieldName['Add To Cart']) {
                  return (
                    <div key={index}>
                      {product?.Variants?.length &&
                      OptionButtonVisibility ===
                        IOptionButtonVisibilty.visible ? (
                        <AddButton
                          AddPosition={AddPosition}
                          onClick={() => navigate(onClick)}
                        >
                          Options
                        </AddButton>
                      ) : (
                        <>
                          {AddProductButtonFunctionality ===
                            IAddProductButtonFunctionality.textAndStepper && (
                            <AddToCartButton
                              type={
                                IAddProductButtonFunctionality.textAndStepper
                              }
                              product={product}
                              url={url}
                            />
                          )}
                          {AddProductButtonFunctionality ===
                            IAddProductButtonFunctionality.stepper && (
                            <AddToCartButton
                              type={IAddProductButtonFunctionality.stepper}
                              product={product}
                              url={url}
                            />
                          )}
                          {AddProductButtonFunctionality ===
                            IAddProductButtonFunctionality.text && (
                            <AddToCartButton
                              type={IAddProductButtonFunctionality.text}
                              product={product}
                              url={url}
                            />
                          )}
                        </>
                      )}
                    </div>
                  );
                }

                if (product?.Brand && value.Name === ProductFieldName.Brand) {
                  return (
                    <p className="brand" key={index}>
                      {product?.Brand}
                    </p>
                  );
                }

                if (
                  product?.RRAmount &&
                  value.Name === ProductFieldName.Rating
                ) {
                  return (
                    <div className="rating" key={index}>
                      <Stack spacing={1}>
                        <Rating
                          name="half-rating-read"
                          value={product.RROverall}
                          precision={0.5}
                          readOnly
                        />
                      </Stack>
                      <span>({product?.RRAmount})</span>
                    </div>
                  );
                }

                if (product?.Name && value.Name === ProductFieldName.Name) {
                  return (
                    <p className="name" key={index}>
                      {product?.Name}
                    </p>
                  );
                }

                if (value.Name === ProductFieldName.Price) {
                  return (
                    <p className="price" key={index}>
                      {(product.Price && product.Price != 0) ?
                        <div>{currency}{product.Price.toFixed(2)} <span className='oldPrice'>{currency}{product.RegularPrice.toFixed(2)}</span></div>
                      : 
                      <div>{currency}{product.RegularPrice.toFixed(2)}</div> 
                      }
                      {/*currency*/}
                      {/*price || ''*/}
                      <span className="pricePerUnit">
                        {pricePerUnit}
                      </span>
                    </p>
                  );
                }

                if (value.Name === ProductFieldName.PromoContent) {
                  if (!product.PromoImage) {
                    return (
                      <div className="promo" key={index}>
                        <p>{product.PromoText}</p>
                      </div>
                    );
                  }
                  if (product.PromoImage) {
                    return (
                      <div className="promo" key={index}>
                        <img
                          src={`${imgSrcByEnv()}${
                            product?.PromoImage && product?.PromoImage.fullpath
                          }`}
                          alt="promo information"
                        />
                        <p>{product.PromoText}</p>
                      </div>
                    );
                  }
                }
                return '';
              }
            )}
        </DescriptionContainer>
      </ProductContainer>
    </LinkStyled>
  );
};
