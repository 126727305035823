import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowDown,
  faArrowRight,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import Button from '@mui/material/Button';
import { motion } from 'framer-motion';
import { VariantsFilter } from '../Filters/VariantsFilter';
import { BrandsFilterModal } from '../Filters/BrandsFilter';
import { FiltersMenuStyled } from './styles';
import { useFiltersMenu } from './useFiltersMenu';
import { IProduct } from '../../types';
import { Sorting } from '../Filters/Sorting';
import { PriceRangeFilter } from '../Filters/PriceRangeFilter';
import { RatingFilter } from '../Filters/RatingFilter';
import { useQuery } from '@apollo/client';
import { GET_LOCALIZED } from '../../queries'
import { useNavigate, useParams } from 'react-router-dom';
import { useConfiguration } from '../../hooks/useConfiguration';

export const FiltersMenu = ({
  productsBeforeFiltering,
}: {
  productsBeforeFiltering: IProduct[];
}) => {
  const {
    modalType,
    handleFiltersVisibility,
    isOpen,
    handleOpen,
    handleUpdate,
    handleReset,
    isInlineFilters,
  } = useFiltersMenu();

  const { storeId = '', harvestId = '' } = useParams();

  const { LanguageVersion } = useConfiguration({ storeId, harvestId });

  const {data: translation} = useQuery(
    GET_LOCALIZED,
    {
      variables: {
        defaultLanguage: LanguageVersion,
      },
    }
  );

  const filters = [
    { name: translation?.getLocalization.SortBy, component: <Sorting buttonsOff={true} /> },
    {
      name: translation?.getLocalization.Price,
      component: (
        <PriceRangeFilter
          buttonsOff={true}
          productsBeforeFiltering={productsBeforeFiltering}
        />
      ),
    },
    { name: translation?.getLocalization.Rating, component: <RatingFilter buttonsOff={true} /> },
    { name: translation?.getLocalization.Variants, component: <VariantsFilter buttonsOff={true} /> },
    {
      name: translation?.getLocalization.Brand,
      component: (
        <BrandsFilterModal
          productsBeforeFiltering={productsBeforeFiltering}
          buttonsOff={true}
        />
      ),
    },
  ];

  const container = {
    hidden: { y: -100, height: 0, opacity: 0 },
    show: {
      y: 0,
      height: 'auto',
      opacity: 1,
    },
  };

  return (
    <FiltersMenuStyled modalType={modalType} isInlineFilters={isInlineFilters}>
      <div className="filter-header">
        <span>{translation?.getLocalization.AllFilters}</span>
        <FontAwesomeIcon icon={faTimes} onClick={handleFiltersVisibility} />
      </div>

      <div className="filters">
        {filters.map((filter: any, index: number) => (
          <React.Fragment key={index}>
            <div className="filter">
              <span onClick={() => handleOpen(filter?.name)}>
                {filter.name}
              </span>
              <FontAwesomeIcon
                onClick={() => handleOpen(filter?.name)}
                icon={isOpen[index] ? faArrowDown : faArrowRight}
              />
            </div>

            <motion.div
              initial={false}
              variants={container}
              animate={isOpen[filter.name] ? 'show' : 'hidden'}
              exit={isOpen[filter.name] ? 'hidden' : 'show'}
              transition={{ ease: 'linear', duration: 0.2 }}
              layout
            >
              {filter.component}
            </motion.div>
          </React.Fragment>
        ))}
      </div>

      <div className="filter-footer">
        <Button fullWidth variant="outlined" onClick={handleReset}>
          {translation?.getLocalization.Reset}
        </Button>
        <Button fullWidth variant="contained" onClick={handleUpdate}>
        {translation?.getLocalization.Update}
        </Button>
      </div>
    </FiltersMenuStyled>
  );
};
