import React from 'react';
import styled from '@emotion/styled';
import { IProduct } from '../../types';
import { imgSrcByEnv } from '../../../config/imgSrcByEnv';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/free-mode';
import 'swiper/css/thumbs';
import { ReactSVG } from 'react-svg';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Thumbs, Navigation } from 'swiper';



export const PdpGallerySlider = ({ product }: { product: IProduct }) => {
  let arr = [""];
  const [thumbsSwiper, setThumbsSwiper] = React.useState<any | null>(null);
  const gallery = product?.Gallery;
  const video = product?.VideoGallery;

  gallery && gallery.map(({ image: { fullpath } }, index) => {
    arr.push(fullpath);
  });

  video && video.map(({ Video: { data, poster }, VideoPosition }, index) => {
    arr.splice( Number(VideoPosition), 0, data.fullpath + '^^^' + poster.fullpath);
  });
  
  arr.shift();

  return (
    <GallerySliderStyled>
      <Swiper
        spaceBetween={10}
        navigation={{
          nextEl: `.arrow-right-image-` + "mainSwiper",
          prevEl: `.arrow-left-image-` + "mainSwiper",
        }}
        thumbs={{ swiper: thumbsSwiper }}
        modules={[FreeMode, Navigation, Thumbs]}
        className="mainSwiper"
      >
        {arr && arr.map((elem) => {
          let videoPaths = elem.split('^^^');
          return (
            <SwiperSlide key={elem}>
              { !elem.includes("Videos") ? 
                <img
                  src={`${imgSrcByEnv()}${elem
                    }`}
                  alt={"test slider image"}
                />
               :
                  <video
                src={`${imgSrcByEnv()}${videoPaths[0]
                  }`} controls autoPlay muted loop playsInline
              />
              }
            </SwiperSlide>
          );
        })
        }
        <ReactSVG
          src="/files/generic/gallery-arrow.svg"
          className={'arrow-left-image-' + "mainSwiper"+" swiper-nav"}
        />
        <ReactSVG
          src="/files/generic/gallery-arrow.svg"
          className={'arrow-right-image-' + "mainSwiper"+" swiper-nav"}
        />
      </Swiper>

      <Swiper
        onSwiper={setThumbsSwiper}
        spaceBetween={5}
        slidesPerView={4}
        createElements={false}
        breakpoints={{
          769: {
            direction: 'vertical',
            setWrapperSize: true,
          },
        }}
        freeMode={true}
        watchSlidesProgress={true}
        modules={[FreeMode, Navigation, Thumbs]}
        className="thumbsSwiper"
        direction={'horizontal'}
      >
        {arr && arr.map((elem) => {
          let videoPaths = elem.split('^^^');
          return (
            <SwiperSlide key={elem}>
              { 
                !elem.includes("Videos") ? 
                <img
                  src={`${imgSrcByEnv()}${elem
                    }`}
                  alt={"test slider image"}
                />
               :
                  <img
                src={`${imgSrcByEnv()}${videoPaths[1]
                  }`} 
              />
              }
            </SwiperSlide>
          );
        })
        }
      </Swiper>
    </GallerySliderStyled>
  );
};

const GallerySliderStyled = styled.div`
  grid-column: 1 / span 2;
  display: flex;
  padding-top: 80px;
  gap: 24px;
  width: 100%;
  position: relative;

@media (min-width: 769px) {
  height: 100%;
  margin-top: auto;
  margin-bottom: auto;
}

@media (max-width: 768px) {
  flex-direction: column;
  padding-top: 24px;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.swiper-slide {
  background-size: cover;
  background-position: center;
}
  
.mainSwiper {
  width: 100%;
  
  .swiper-nav{
    user-select: none;
    position: absolute;
    top: 50%;
    z-index: 1000;

    &.swiper-button-disabled{
      display:none;
    }
    &.arrow-left-image-mainSwiper{
      left: 0;
      transform: translateY(-50%) rotate(90deg) scale(80%);
    }
    &.arrow-right-image-mainSwiper{
      right: 0;
      transform: translateY(-50%) rotate(-90deg) scale(80%);
    }
  }

  .swiper-slide{
    height: auto;

    img{
      object-fit: contain;
      width: 100%;
      max-width: 490px;
      max-height: 600px;
    }
  }
}

.thumbsSwiper {
  height: 100%;
  box-sizing: border-box;
  padding: 10px 0;

  @media (max-width:768px){
    .swiper-wrapper{
      height: max-content!important;
    }
  }
  
  @media (min-width:769px){
    height: 80%;
    margin: auto 0;
    order:-1;
    width:30%;
    .swiper-wrapper {
      position: absolute;
    }
  }

  .swiper-slide {
    width: auto;
    height: auto;
    opacity: 0.4;

    img {
      display: block;
      max-height: 120px;
      max-width: 120px;
      object-fit: contain;
    }
    
    video {
      display: block;
      max-height: 120px;
      max-width: 120px;
      object-fit: contain;
    }
  }
}

.thumbsSwiper .swiper-slide-thumb-active {
  opacity: 1;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.swiper-slide video {
  display: block;
  width: 100%;
  object-fit: contain;
}
`