import React from 'react';

import styled from '@emotion/styled';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import { useAppContext } from '../../../context';
import { actions } from '../../../context/actions';
import { FilterModalCommonStyles } from '../commonStyles';
import { FilterModalButtons } from '../../FilterModalButtons';
import { useParams } from 'react-router-dom';
import { useConfiguration } from '../../../hooks/useConfiguration';
import { GET_LOCALIZED } from '../../../queries';
import { useQuery } from '@apollo/client';

export const Sorting = ({ buttonsOff }: any) => {
  const [value, setValue] = React.useState('');

  const { storeId = '', harvestId = '' } = useParams();

  const { LanguageVersion } = useConfiguration({ storeId, harvestId });

  const {data: translation} = useQuery(
    GET_LOCALIZED,
    {
      variables: {
        defaultLanguage: LanguageVersion,
      },
    }
  );

  const {
    dispatch,
    value: { productsSorting },
  } = useAppContext();

  const handleSubmit = () => {
    value?.length &&
      dispatch({
        type: actions.ADD_PRODUCTS_SORTING,
        payload: { value },
      });
    dispatch({
      type: actions.TOGGLE_MODAL,
      payload: { isModal: false, modalContent: null },
    });
  };

  const handleClearSorting = () => {
    dispatch({
      type: actions.REMOVE_PRODUCTS_SORTING,
    });
  };

  React.useEffect(() => {
    setValue(productsSorting && productsSorting[0]?.value);
  }, [productsSorting]);

  return (
    <FilterModalCommonStyles buttonsOff={buttonsOff}>
      <ComponentStyled>
        <h2>{translation?.getLocalization.Sort}</h2>
        <SortComponentStyled>
          <div className="sort-list-container">
            <div className="sort-list">
              <FormControl>
                <RadioGroup
                  onChange={(e) => setValue(e.target.value)}
                  value={value}
                >
                  <FormControlLabel
                    value="Alphabet:A>Z"
                    control={<Radio />}
                    label={translation?.getLocalization.AlphabetAZ}
                  />
                  <FormControlLabel
                    value="Alphabet:Z>A"
                    control={<Radio />}
                    label={translation?.getLocalization.AlphabetZA}
                  />
                  <FormControlLabel
                    value="Price:low>high"
                    control={<Radio />}
                    label={translation?.getLocalization.PriceLH}
                  />
                  <FormControlLabel
                    value="Price:high>low"
                    control={<Radio />}
                    label={translation?.getLocalization.PriceHL}
                  />
                  <FormControlLabel
                    value="Rating:low>high"
                    control={<Radio />}
                    label={translation?.getLocalization.RatingLH}
                  />
                  <FormControlLabel
                    value="Rating:high>low"
                    control={<Radio />}
                    label={translation?.getLocalization.RatingHL}
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </div>
        </SortComponentStyled>
        <div className="buttons">
          <FilterModalButtons
            onSubmit={handleSubmit}
            onClear={handleClearSorting}
          />
        </div>
      </ComponentStyled>
    </FilterModalCommonStyles>
  );
};

const ComponentStyled = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  h2 {
    font-size: 1.7rem;
    font-weight: 600;
  }
`;

const SortComponentStyled = styled.div`
  flex: 1;

  .sort-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 2rem;
    height: 30px;
    & > span {
      font-size: 1.7rem;
      font-weight: 500;
    }
    & > div {
      span:first-of-type {
        font-size: 1.5rem;
        font-weight: 400;
      }
      span:last-of-type {
        margin-left: 30px;
      }
    }
    .dash {
      font-size: 2rem;
    }
    .plus {
      font-size: 3rem;
    }
  }
  .sort-list-container {
    overflow: hidden;
  }
  .sort-list {
    margin-top: 5%;
    padding: 10px;

    .MuiFormControlLabel-label {
      font-size: 1.3rem;
    }
  }
`;
