import React from 'react';

import styled from '@emotion/styled';

import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';
import Rating from '@mui/material/Rating';
import { FilterModalCommonStyles } from '../commonStyles';
import { FilterModalButtons } from '../../FilterModalButtons';
import { useAppContext } from '../../../context';
import { actions } from '../../../context/actions';
import { useParams } from 'react-router-dom';
import { useConfiguration } from '../../../hooks/useConfiguration';
import { GET_LOCALIZED } from '../../../queries';
import { useQuery } from '@apollo/client';

export const RatingFilter = ({ buttonsOff }: any) => {
  const {
    value: { searchFilters },
    dispatch,
  } = useAppContext();

  const { storeId = '', harvestId = '' } = useParams();

  const { LanguageVersion } = useConfiguration({ storeId, harvestId });

  const {data: translation} = useQuery(
    GET_LOCALIZED,
    {
      variables: {
        defaultLanguage: LanguageVersion,
      },
    }
  );

  const ratingFilter = searchFilters.find(
    (filter: any) => filter.name === 'Rating'
  );

  const handleSubmit = () => {
    dispatch({
      type: actions.ADD_SEARCH_FILTER,
      payload: { name: 'Rating', value },
    });
    dispatch({
      type: actions.TOGGLE_MODAL,
      payload: { isModal: false, modalContent: null },
    });
  };
  const handleClear = () => {
    setValue(null);
    dispatch({
      type: actions.REMOVE_SEARCH_FILTER,
      payload: { name: 'Rating', value: null },
    });
  };
  const [value, setValue] = React.useState(ratingFilter?.value || '');

  return (
    <FilterModalCommonStyles buttonsOff={buttonsOff}>
      <ComponentStyled>
        <h2>{translation?.getLocalization.Rating}</h2>

        <FormControl>
          <RadioGroup onChange={(e) => setValue(e.target.value)} value={value}>
            <FormControlLabel
              value={4}
              control={<Radio />}
              label={<RatingComponent rating={4} text={translation?.getLocalization.FourOrMore} />}
            />
            <FormControlLabel
              value={3}
              control={<Radio />}
              label={<RatingComponent rating={3} text={translation?.getLocalization.ThreeOrMore} />}
            />
            <FormControlLabel
              value={2}
              control={<Radio />}
              label={<RatingComponent rating={2} text={translation?.getLocalization.TwoOrMore} />}
            />
            <FormControlLabel
              value={1}
              control={<Radio />}
              label={<RatingComponent rating={1} text={translation?.getLocalization.OneOrMore} />}
            />
          </RadioGroup>
        </FormControl>
        <div className="buttons">
          <FilterModalButtons onSubmit={handleSubmit} onClear={handleClear} />
        </div>
      </ComponentStyled>
    </FilterModalCommonStyles>
  );
};

const ComponentStyled = styled.div``;

const RatingComponent = ({
  rating,
  text,
}: {
  rating: number;
  text: string;
}) => {
  return (
    <RatingStyled spacing={1} className="stack">
      <Rating
        name="half-rating-read"
        defaultValue={rating}
        precision={0.5}
        readOnly
      />
      <div className="text">{text}</div>
    </RatingStyled>
  );
};

const RatingStyled = styled(Stack)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  .text {
    margin: 0 0 0 10px;
  }
`;
